import React, {useState, useEffect} from 'react';
import SideMenu from "Components/SideMenu";
import Breadcrumb from "Components/Breadcrumb";
import {LockOutlined} from "@mui/icons-material";
import {Pagination} from "@mui/material";
import {useNavigate} from "react-router-dom";
import moment from 'moment';
import * as Apis from 'Api';

function MyQnaHistory() {
    const navigate = useNavigate();
    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        Apis.qnaMyList().then((resp) => {
            setItemList(resp);
        }).catch((e) => {

        });
    }

    const renderItem = (item) => {
        return (
            <div className={'qna-history-line'} onClick={() => navigate('/profile/my-qna-history/' + item.id)} key={item.id}>
                <div className={'history-info'}>
                    {
                        item.qnaStatus === 'PENDING' ?
                            <span className={'history-status'}>답변대기</span> :
                            <span className={'history-status finish'}>답변완료</span>
                    }
                    <span className={'history-title'}>[{Apis.values.qnaConsultingType[item.qnaConsulting]}]{item.qnaTitle} {!item.qnaPublic && <LockOutlined fontSize={'small'} color={'action'}/>}</span>
                    <span className={'history-content'}>{item.qnaContent}</span>
                </div>
                <span className={'history-time'}>{moment(item.createdAt).format('YYYY-MM-DD')}</span>
            </div>
        )
    }

    return (
        <div className={'page-center'}>
            <SideMenu
                title={'마이페이지'}
                menu={[
                    {label: '내정보', link: '/profile/my-info'},
                    {label: '상담하기', link: '/profile/my-qna'},
                    {label: '나의 상담이력', link: '/profile/my-qna-history', active: true},
                ]}
            />
            <div className={'page-content'}>
                <Breadcrumb value={['홈', '마이페이지', '나의 상담이력']}/>
                <div className={'border-bottom pb-2'}>
                    <img src={require('Assets/img/icon_qna_blue.svg').default} alt=''/><span className={'fs-18 fw-bold text-desc ms-2'}>나의 상담이력</span>
                </div>
                <div className={'my-qna-history'}>
                    {
                        itemList.map((v) => renderItem(v))
                    }
                </div>
                {/*<div className={'d-flex justify-content-center mt-4'}>*/}
                {/*    <Pagination count={10} color={'primary'} shape={'rounded'}/>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default MyQnaHistory;