import React from 'react';
import {Button, IconButton, Dialog as MuiDialog} from '@mui/material';
import {Close} from '@mui/icons-material';
import PropTypes from "prop-types";

Dialog.propTypes = {
    showCancel: PropTypes.bool,
}

Dialog.defaultProps = {
    showCancel: true
}

function Dialog({title, children, open, confirmLabel, onClose, onConfirm, showCancel}) {
    if(!open) return null;

    return (
        <MuiDialog open={open} onClose={onClose} className={'dialog-container'} maxWidth={'lg'}>
            <div className={'dialog-title'}>
                <span>{title}</span>
                <IconButton onClick={onClose}><Close /></IconButton>
            </div>
            <div className={'dialog-content'}>
                {children}
            </div>
            <div className={'dialog-action'}>
                {showCancel && <Button variant={'contained'} color={'warning'} className={'cancel-btn'} onClick={onClose}>취소</Button>}
                {confirmLabel && <Button variant={'contained'} onClick={onConfirm}>{confirmLabel}</Button>}
            </div>
        </MuiDialog>
    )
}

export default Dialog;