import React from 'react';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

const Footer = () => {
    const siteSetting = useSelector(state => state.authUser.siteSetting);

    return (
        <footer className={'site-footer'}>
            <div className='d-flex flex-column flex-md-row'>
                <div className={'me-md-5 mb-3'}>
                    <img className={'footer-logo'} src={require('Assets/img/logo1.svg').default} alt=''/>
                </div>
                <div className={'footer-info fs-14'}>
                    <div className={'d-flex flex-row justify-content-between mb-2'}>
                        <span className={'text-desc fs-16 fw-bold'}>인천광역시무료노동상담소</span>
                        <div className={'footer-line1 d-flex flex-row'}>
                            <NavLink to={'/privacy'} className={'text-desc'}>개인정보처리방침</NavLink>
                            <span className={'divider'}/>
                            <NavLink to={'/usage'} className={'text-desc'}>이용약관</NavLink>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: siteSetting['footer']}} className={'text-desc'}/>
                    <div className={'mt-4 text-gray'}>Copyright 친절한,노동상담소. All rights reserved.</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer