import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookie = new Cookies();

function getApiHost()
{
    let hostname = window.location.hostname;
    if (
        hostname === 'localhost' ||
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(hostname)
    )
    {
        hostname = hostname + ':5000';
    }
    return window.location.protocol + '//' + hostname;
}

export const apiHost = getApiHost();

export const apiAddress = apiHost + '/api/';
export const imageBasePath = apiAddress + '/upload/';

export const values = {
    qnaConsultingType: {DELAY_WAGE: '임금체불', UNFAIR_DISMISSAL: '부당해고', NON_REGULAR: '비정규직', LEGAL: '생활법률', OTHER: '기타'},
    qnaStatus: {'PENDING': '답변대기', 'FINISH': '답변완료'},
    qnaContractTerm: { REGULAR: "정규직", IRREGULAR: "비정규직" },
    qnaWorkerNum: {OVER_5: '5인 이상', UNDER_5: '5인 미만'},
    qnaWorkType: {WORKER: '근로자', MASTER: '사업주', FREELANCER: '프리랜서'}
}

const instance = axios.create({
    baseURL: apiAddress,
    timeout: 40000
});

instance.interceptors.request.use(function (config) {
    const token = cookie.get("access_token");
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401 && error.request.responseURL.indexOf('api/users/login') === -1) {
        // logout status
        cookie.remove('user_info', {path: '/'});
        cookie.remove('access_token', {path: '/'});
        window.location.href = '/login';
    }
    throw error;
});

export const login = (username, password, naverToken) => {
    const url = '/users/login';
    const req = {
        username,
        password,
        naverToken
    };
    return instance.post(url, req).then((response) => response.data);
}

export function logout() {
    const url = '/users/logout';
    return instance.get(url).then((response) => response.data);
}

export const signup = (data) => {
    const url = '/users';
    return instance.post(url, data).then((response) => response.data);
}

export const getUserInfo = () => {
    const url = '/users/info';
    return instance.get(url).then((response) => response.data);
}

export const setUserInfo = (name) => {
    const url = '/users/info';
    return instance.post(url, {name}).then((response) => response.data);
}

export const changeUserPassword = (oldPassword, newPassword) => {
    const url = '/users/password';
    return instance.post(url, {oldPassword, newPassword}).then((response) => response.data);
}

export const changeUserPushSetting = (isAllow) => {
    const url = `/users/push-set?isAllow=${isAllow}`;
    return instance.get(url).then((response) => response.data);
}

export const resetPassword = (username, email) => {
    const url = `/users/reset-password`;
    return instance.post(url, {username, email}).then((response) => response.data);
}

export const setPushToken = (token) => {
    const url = `/users/pushToken`;
    return instance.post(url, {pushToken: token}).then((response) => response.data);
}

/**
 * privacy functions
 */

export function getAppIntro() {
    const url = `/settings/appIntro`;
    return instance.get(url).then((response) => response.data);
}

export function getPrivacy() {
    const url = `/settings/privacy`;
    return instance.get(url).then((response) => response.data);
}

export function getTerms() {
    const url = `/settings/terms`;
    return instance.get(url).then((response) => response.data);
}

/**
 * qna functions
 */
export function qnaList(page, count = 9, searchData) {
    const search = JSON.stringify(searchData);
    const sort = JSON.stringify({field: 'createdAt', asc: false});
    const url = `/qnas?page=${page}&count=${count}&search=${search}&sort=${sort}`;
    return instance.get(url).then((response) => response.data);
}

export function qnaInfo(id) {
    const url = `/qnas/${id}`;
    return instance.get(url).then((response) => response.data);
}

export function qnaAdd(data) {
    const url = `/qnas`;
    return instance.post(url, data).then((response) => response.data);
}

export function qnaUpdate(id, data) {
    const url = `/qnas/${id}`;
    return instance.patch(url, data).then((response) => response.data);
}

export function qnaDelete(id) {
    const url = `/qnas/${id}`;
    return instance.delete(url).then((response) => response.data);
}

export function qnaMyList() {
    const url = `/qnas/my-list`;
    return instance.get(url).then((response) => response.data);
}

export const qnaUploadFile = (data) => {
    const url = `/qnas/upload-file`;
    const config = {
        timeout: 0,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        // onUploadProgress: function (progressEvent) {
        //   let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //   onProgress(percent);
        // }
    };
    return instance.post(url, data, config).then((response) => response.data);
}

/**
 * issue functions
 */
export function issueList(page, count = 9) {
    const search = JSON.stringify({issueType: 'ISSUE'});
    const sort = JSON.stringify({field: 'createdAt', asc: false});
    const url = `/issues?page=${page}&count=${count}&search=${search}&sort=${sort}&device=web`;
    return instance.get(url).then((response) => response.data);
}

export function knowledgeList(page, count = 9) {
    const search = JSON.stringify({issueType: 'KNOWLEDGE'});
    const sort = JSON.stringify({field: 'createdAt', asc: false});
    const url = `/issues?page=${page}&count=${count}&search=${search}&sort=${sort}&device=web`;
    return instance.get(url).then((response) => response.data);
}

export function lawInfoList(page, count = 9) {
    const search = JSON.stringify({issueType: 'LAW_INFO'});
    const sort = JSON.stringify({field: 'createdAt', asc: false});
    const url = `/issues?page=${page}&count=${count}&search=${search}&sort=${sort}&device=web`;
    return instance.get(url).then((response) => response.data);
}

export function issueInfo(id) {
    const url = `/issues/${id}`;
    return instance.get(url).then((response) => response.data);
}

export function searchData(searchText) {
    const url = `/issues/search?text=${searchText}`;
    return instance.get(url).then((response) => response.data);

}

/**
 * banner functions
 */
export function bannerList() {
    const url = `/banners/list?device=web`;
    return instance.get(url).then((response) => response.data);
}


/**
 * notice functions
 */
export function noticeList() {
    const url = `/notices`;
    return instance.get(url).then((response) => response.data);
}

/**
 * link functions
 */
export function linkList() {
    const url = `/settings/links`;
    return instance.get(url).then((response) => response.data);
}