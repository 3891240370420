import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {ArrowForwardIosRounded, ArrowBackIosRounded} from '@mui/icons-material';
import SideMenu from "Components/SideMenu";
import * as Apis from "../../Api";
import moment from "moment";
import QnaViewComponent from "../../Components/QnaViewComponent";

function QnaView() {
    const params = useLocation();
    const navigate = useNavigate();
    const [itemList, setItemList] = useState([]);
    const [itemIndex, setItemIndex] = useState(null);
    const [itemInfo, setItemInfo] = useState({});

    useEffect(() => {
        setItemList(params.state.itemList || []);
        setItemIndex(params.state.itemIndex !== undefined ? params.state.itemIndex : 0);
    }, []);

    const onChangeItem = (step) => {
        console.log(step, itemList[itemIndex])
        setItemIndex(itemIndex + step);
        window.scrollTo(0, 0);
    }

    return (
        <div className={'page-center'}>
            <SideMenu title={'상담게시판'} menu={[]}/>
            <div className={'page-content'}>
                {itemIndex !== null && itemList[itemIndex] && <QnaViewComponent id={itemList[itemIndex]} /> }
                <div className={'d-flex flex-row justify-content-center mt-4 issue-view-buttons'}>
                    <Button variant={'outlined'} size={'small'} className={'mx-1'} disabled={itemIndex === 0} onClick={() => onChangeItem(- 1)}>
                        <ArrowBackIosRounded fontSize={'small'}/> 이전글
                    </Button>
                    <Button variant={'contained'} size={'small'} className={'mx-1'} onClick={() => navigate(-1)}>
                        목록으로
                    </Button>
                    <Button variant={'outlined'} className={'mx-1'} disabled={itemIndex === itemList.length - 1} onClick={() => onChangeItem(1)}>
                        다음글 <ArrowForwardIosRounded fontSize={'small'}/>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default QnaView;