import React from 'react';
import { useParams } from "react-router-dom";
import QnaViewComponent from "Components/QnaViewComponent";
import SideMenu from "Components/SideMenu";
import {useSelector} from "react-redux";

function MyQnaView() {
    const param = useParams();

    return (
        <div className={'page-center'}>
            <SideMenu
                title={'마이페이지'}
                menu={[
                    {label: '내정보', link: '/profile/my-info'},
                    {label: '상담하기', link: '/profile/my-qna'},
                    {label: '나의 상담이력', link: '/profile/my-qna-history', active: true},
                ]}
            />
            <div className={'page-content'}>
                <QnaViewComponent id={param.id} />
            </div>
        </div>
    )
}

export default MyQnaView;