import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {ArrowForwardIosRounded, ArrowBackIosRounded} from '@mui/icons-material';
import SideMenu from "Components/SideMenu";
import * as Apis from "../../Api";
import moment from "moment";

function IssueView() {
    const params = useLocation();
    const navigate = useNavigate();
    const [itemList, setItemList] = useState([]);
    const [itemIndex, setItemIndex] = useState(null);
    const [itemInfo, setItemInfo] = useState({});

    useEffect(() => {
        setItemList(params.state.itemList || []);
        setItemIndex(params.state.itemIndex !== undefined ? params.state.itemIndex : 0);
        getData();
    }, []);

    useEffect(() => {
        itemIndex !== null && getData();
    }, [itemIndex]);

    const getData = () => {
        if(itemList[itemIndex]) {
            Apis.issueInfo(itemList[itemIndex]).then((resp) => {
                setItemInfo(resp);
            }).catch((e) => {

            })
        }
    }

    const onChangeItem = (step) => {
        setItemIndex(itemIndex + step);
        window.scrollTo(0, 0);
    }

    return (
        <div className={'page-center'}>
            <SideMenu title={params.state.side} menu={[]}/>
            <div className={'page-content'}>
                <div className={'d-flex flex-row justify-content-end text-desc1 fs-14 mb-1'}>
                    {moment(itemInfo.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div className={'border-top'}>
                    <div className={'fs-21 text-desc fw-semi-bold mt-2 mb-5'}>{itemInfo.issueTitle}</div>
                    <div className={'d-flex flex-row justify-content-center mb-4'}>
                        <img src={Apis.imageBasePath + itemInfo.issueImage} alt={''} height={120}/>
                    </div>
                        <div className={'issue-view-content'} dangerouslySetInnerHTML={{__html: itemInfo.issueContent}}/>
                </div>
                <div className={'d-flex flex-row justify-content-end mt-4 issue-view-buttons'}>
                    <Button variant={'outlined'} size={'small'} className={'mx-1'} disabled={itemIndex === 0} onClick={() => onChangeItem(- 1)}>
                        <ArrowBackIosRounded fontSize={'small'}/> 이전글
                    </Button>
                    <Button variant={'contained'} size={'small'} className={'mx-1'} onClick={() => navigate(-1)}>
                        목록으로
                    </Button>
                    <Button variant={'outlined'} className={'mx-1'} disabled={itemIndex === itemList.length - 1} onClick={() => onChangeItem(1)}>
                        다음글 <ArrowForwardIosRounded fontSize={'small'}/>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default IssueView;