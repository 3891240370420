/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
import {Cookies} from 'react-cookie';
import { LOGIN_USER_SUCCESS, LOGOUT_USER, UPDATE_USER_INFO, UPDATE_COMPANY, UPDATE_SITE_SETTING} from 'Store/Actions/types';
import * as Apis from 'Api';

const cookie = new Cookies();

export const signinUser = (info, accessToken, navigate) => (dispatch) => {
    if (Apis.apiHost.indexOf(':') !== -1) {
        cookie.set('user_info', info, {path: '/'});
        cookie.set('access_token', accessToken, {path: '/'});
    }
    dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: {
            info: info,
            accessToken: accessToken,
        }
    });
    navigate('/');
}

/**
 * Redux Action To Signout User From  Firebase
 */
export const logoutUser = () => (dispatch) => {
    if (Apis.apiHost.indexOf(':') !== -1) {
        cookie.remove('user_info', {path: '/'});
        cookie.remove('access_token', {path: '/'});
    }
    dispatch({type: LOGOUT_USER});
}

export const updateUserInfo = (info) => (dispatch) => {
    cookie.set('user_info', info, {path: '/'});
    dispatch({
        type: UPDATE_USER_INFO,
        payload: info
    });
}

export const updateCompanyList = (list) => (dispatch) => {
    cookie.set('company_list', list, {path: '/'});
    dispatch({
        type: UPDATE_COMPANY,
        payload: list
    });
}

export const updateSiteSetting = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_SITE_SETTING,
        payload: data
    });
}