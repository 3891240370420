import React, {useState, useEffect} from 'react';
import {Carousel} from 'react-responsive-carousel';
import {IconButton, InputAdornment, TextField, Button} from '@mui/material';
import {ArrowBackIosRounded, ArrowForwardIosRounded, Search, EmailOutlined} from '@mui/icons-material';
import {useSelector} from "react-redux";
import * as Apis from 'Api';
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";

function HomePage() {
    const navigate = useNavigate();
    const siteSetting = useSelector(state => state.authUser.siteSetting);
    const [mainBanners, setMainBanners] = useState([]);
    const [subBanners, setSubBanners] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        Apis.bannerList().then((resp) => {
            setMainBanners(resp.filter((v) => v.bannerType === 'MAIN_BANNER'));
            setSubBanners(resp.filter((v) => v.bannerType === 'SUB_BANNER'));
        });
    }, []);

    const onSearch = (e) => {
        if(e.key === 'Enter') {
            navigate('/search?text=' + searchText);
        }
    }

    const renderSlideArrowPrev = (clickHandler) => {
        return (
            <div className={'slide-arrow prev'}>
                <IconButton onClick={clickHandler}><ArrowBackIosRounded/></IconButton>
            </div>
        )
    }

    const renderSlideArrowNext = (clickHandler) => {
        return (
            <div className={'slide-arrow next'}>
                <IconButton onClick={clickHandler}><ArrowForwardIosRounded/></IconButton>
            </div>
        )
    }

    return (
        <div className={'page-container home-container'}>
            <Carousel
                showThumbs={false} showStatus={false} autoPlay infiniteLoop
                renderArrowPrev={renderSlideArrowPrev}
                renderArrowNext={renderSlideArrowNext}
            >
                {
                    mainBanners.map((v, i) => <div key={i}><img src={Apis.imageBasePath + (isMobile ? v.appImage : v.image)} alt=''/></div>)
                }
            </Carousel>
            <div className={'home-content'}>
                <div className={'d-flex flex-column justify-content-center align-items-center'}>
                    <div className={'home-bar'}/>
                    <span className={'fs-24 text-black mt-3'}>노동, 궁금하신가요? 무엇이든 물어보세요</span>
                    <TextField
                        variant="outlined"
                        placeholder={'검색'}
                        className={'mt-3 mb-2'}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
                        }}
                        onKeyDown={onSearch}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <div className={'d-flex flex-column flex-md-row align-items-center mt-3'}>
                        <div className={'page-title fs-21 text-desc'}>궁금증이 해결되지 않았나요? 직접 문의하세요</div>
                        <Button variant={'contained'} className={'ms-md-4 mt-3 mt-md-0'} onClick={() => navigate('/profile/my-qna')}>
                            <img src={require('Assets/img/icon_qna.svg').default} alt=''/>
                            <span className={'fs-20 fw-semi-bold'}>무료 상담하기</span>
                        </Button>
                    </div>
                </div>
                <div className={'d-flex flex-column flex-md-row card-container'}>
                    {
                        subBanners.map((v, i) => (
                            <div className={'home-card'} key={i}>
                                <img src={Apis.imageBasePath + v.image} alt={''}/>
                                <Button variant={'contained'} onClick={() => {window.location.href = v.link}}>자세히보기</Button>
                            </div>
                        ))
                    }
                </div>
                <div className={'d-flex flex-column flex-md-row home-info-container'}>
                    <div className={'link-card-container'}>
                        <div className={'link-card-line'}>
                            <div style={{backgroundColor: '#1f95c8'}} onClick={() => {window.open(siteSetting['최저임금 계산기'])}}>
                                <img style={{width: 37}} src={require('Assets/img/home/icon_bag.svg').default} alt=''/><span>최저임금 계산기</span>
                            </div>
                            <div style={{backgroundColor: '#1160aa'}} onClick={() => {window.open(siteSetting['퇴직금 계산기'])}}>
                                <img style={{width: 36}} src={require('Assets/img/home/icon_payments.svg').default} alt=''/><span>퇴직금 계산기</span>
                            </div>
                        </div>
                        <div className={'link-card-line'}>
                            <div style={{backgroundColor: '#f2855e'}} onClick={() => {window.open(siteSetting['실업급여 계산기'])}}>
                                <img style={{width: 30}} src={require('Assets/img/home/icon_find.svg').default} alt=''/><span>실업급여 계산기</span>
                            </div>
                            <div style={{backgroundColor: '#f7b679'}} onClick={() => {window.open(siteSetting['연차휴가 계산기'])}}>
                                <img style={{width: 38}} src={require('Assets/img/home/icon_umbrella.svg').default} alt=''/><span>연차휴가 계산기</span>
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex flex-column justify-content-center site-phone-info'}>
                        <div className={'phone-card-container'}>
                            <div className={'d-flex flex-column flex-md-row'}>
                                <div className={'phone-card'}>
                                    <img src={require('Assets/img/icon_phone.svg').default} alt=''/>
                                    <div className={'d-flex flex-column fs-16 ms-2'}>
                                        <span>구월노동법률상담소</span>
                                        <span className={'fw-bold'}>032-437-8501</span>
                                    </div>
                                </div>
                                <div className={'phone-card'}>
                                    <img src={require('Assets/img/icon_phone.svg').default} alt=''/>
                                    <div className={'d-flex flex-column fs-16 ms-2'}>
                                        <span>서부노동법률상담소</span>
                                        <span className={'fw-bold'}>032-571-8508</span>
                                    </div>
                                </div>
                            </div>
                            <div className={'d-flex flex-column flex-md-row'}>
                                <div className={'phone-card'}>
                                    <img src={require('Assets/img/icon_phone.svg').default} alt=''/>
                                    <div className={'d-flex flex-column fs-16 ms-2'}>
                                        <span>남동근로자종합복지관</span>
                                        <span className={'fw-bold'}>032-818-1300</span>
                                    </div>
                                </div>
                                <div className={'phone-card'}>
                                    <img src={require('Assets/img/icon_phone.svg').default} alt=''/>
                                    <div className={'d-flex flex-column fs-16 ms-2'}>
                                        <span>영종노동법률상담소</span>
                                        <span className={'fw-bold'}>032-746-8501</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'fs-13 text-center text-desc1 my-2'}>
                            평일 10:00 ~ 19:00(점심시간 12:00 ~ 13:00) 토요일, 공휴일 휴무
                        </div>
                        <div className={'email-btn pt-2'}>
                            <Button variant={'contained'} onClick={() => window.open('mailto:inochonglabor@naver.com')}>
                                <EmailOutlined/>
                                <span>이메일문의:  inochonglabor@naver.com</span>
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
            <div className={'home-footer'}>
                <div className={'d-flex flex-column-reverse flex-md-row home-footer-content'}>
                    <img className='home-footer-img' src={require('Assets/img/home/bottom_img.png')} alt='' />
                    <div className={'home-footer-app d-flex flex-column justify-content-center'}>
                        <div className={'home-footer-label'}>앱으로 편리하게 이용하세요.</div>
                        <div className={'home-footer-desc'}>앱스토어, 구글플레이에서 "노동상담소"로 검색하세요</div>
                        <div className={'d-flex flex-row mt-4'}>
                            <Button variant={'contained'} className={'store-btn'} onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.hazon.labor')}}>
                                <img src={require('Assets/img/home/playstore.png')} alt='' />
                            </Button>
                            <Button variant={'contained'} className={'store-btn'} onClick={() => {window.open('https://apps.apple.com/kr/app/%EB%85%B8%EB%8F%99%EC%83%81%EB%8B%B4%EC%86%8C/id1610293530')}}>
                                <img src={require('Assets/img/home/appstore.png')} alt='' />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'home-logo-container'}>
                <div onClick={() => {window.open(siteSetting['고용노동부민원마당'])}}>
                    <img style={{width: isMobile ? 90 : 210}} src={require('Assets/img/home/bottom_logo1.png')} alt='' />
                </div>
                <div onClick={() => {window.open(siteSetting['한국노총인천지역본부'])}}>
                    <img style={{width: isMobile ? 90 : 210}} src={require('Assets/img/home/bottom_logo2.png')} alt='' />
                </div>
                <div onClick={() => {window.open(siteSetting['마음사이다 인천광역시 노사민정'])}}>
                    <img style={{width: isMobile ? 70 : 140}} src={require('Assets/img/home/bottom_logo3.png')} alt='' />
                </div>
            </div>
        </div>
    )
}

export default HomePage;