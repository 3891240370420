import React, {useState, useEffect} from "react";
import Dialog from "../../Components/Dialog";
import * as Apis from "../../Api";

function PrivacyModal({open, onClose, type}) {
    const [text, setText] = useState('');

    useEffect(() => {
        if(type === 'privacy') {
            Apis.getPrivacy().then((resp) => {
                setText(resp.value);
            });
        } else {
            Apis.getTerms().then((resp) => {
                setText(resp.value);
            });
        }
    }, [])


    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={type === 'privacy' ? '개인정보처리방침' : '이용약관'}
            showCancel={false}
        >
            <div className={'privacy-modal'}>
                <div dangerouslySetInnerHTML={{__html: text}} />
            </div>
        </Dialog>
    )
}

export default PrivacyModal;