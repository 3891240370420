import React, {useState, useEffect} from 'react';
import {Button, IconButton} from '@mui/material';
import {PersonOutlineOutlined, Close} from '@mui/icons-material';
import SideMenu from "Components/SideMenu";
import Breadcrumb from "Components/Breadcrumb";
import ModalCompanyEdit from "./ModalCompanyEdit";
import ModalCompanyDel from "./ModalCompanyDel";
import ModalUsername from "./ModalUsername";
import ModalPassword from "./ModalPassword";
import {useDispatch, useSelector} from "react-redux";
import {updateUserInfo, updateCompanyList} from 'Store/Actions';
import * as Apis from 'Api';

function MyInfo() {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.authUser.userInfo);
    const companyList = useSelector(state => state.authUser.companyList);
    const [showUsernameModal, setShowUsernameModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [delCompanyId, setDelCompanyId] = useState(null);
    const [editCompanyId, setEditCompanyId] = useState(null);

    useEffect(() => {
        Apis.getUserInfo().then((resp) => {
           dispatch(updateUserInfo(resp));
        }).catch((e) => {

        });
    }, []);

    const onDefaultCompany = (index) => {
        companyList.forEach((v) => v.isDefault = false);
        companyList[index].isDefault = true;
        dispatch(updateCompanyList([...companyList]));
    }

    const renderCompany = (item, index) => {
        return (
            <div className={'company-line'} key={index}>
                <Button variant={item.isDefault ? 'contained' : 'outlined'} size={'small'} onClick={() => onDefaultCompany(index)}>기본</Button>
                <span className={'ms-2 text-desc btn-underline'} onClick={() => setEditCompanyId(index)}>{item.companyTitle}</span>
                <IconButton size={'small'} className={'ms-2'} onClick={() => setDelCompanyId(index)}><Close/></IconButton>
            </div>
        )
    }

    return (
        <div className={'page-center'}>
            <SideMenu
                title={'마이페이지'}
                menu={[
                    {label: '내정보', link: '/profile/my-info', active: true},
                    {label: '상담하기', link: '/profile/my-qna'},
                    {label: '나의 상담이력', link: '/profile/my-qna-history'},
                ]}
            />
            <div className={'page-content'}>
                <Breadcrumb value={['홈', '마이페이지', '내정보']}/>
                <div className={'border-bottom pb-2'}>
                    <PersonOutlineOutlined className={'text-main'}/><span className={'fs-18 fw-bold text-desc ms-2'}>내정보</span>
                </div>
                <div className={'profile-line'}>
                    <span>이름</span>
                    <span className={'btn-underline text-desc'} onClick={() => setShowUsernameModal(true)}>{userInfo?.username}</span>
                </div>
                <div className={'profile-line'}>
                    <span>아이디(이메일)</span>
                    <span>{userInfo?.email}</span>
                </div>
                <div className={'profile-line'}>
                    <span>비밀번호</span>
                    <span className={'btn-underline text-desc'} onClick={() => setShowPasswordModal(true)}>변경하기</span>
                </div>
                <div className={'d-flex flex-row justify-content-between align-items-center border-bottom pb-2 mt-5'}>
                    <div className={'d-flex flex-row'}>
                        <img src={require('Assets/img/icon_company.svg').default} alt='' width={25}/><span className={'fs-18 fw-bold text-desc ms-2'}>직장정보</span>
                    </div>
                    <Button variant={'contained'} size={'small'} onClick={() => setEditCompanyId(-1)}>등록</Button>
                </div>
                {
                    companyList.map((v, i) => renderCompany(v, i))
                }
            </div>
            <ModalUsername open={showUsernameModal} onClose={() => setShowUsernameModal(false)} />
            <ModalPassword open={showPasswordModal} onClose={() => setShowPasswordModal(false)} />
            <ModalCompanyDel open={delCompanyId !== null} delIndex={delCompanyId} onClose={() => setDelCompanyId(null)} />
            <ModalCompanyEdit open={editCompanyId !== null} editIndex={editCompanyId} onClose={() => setEditCompanyId(null)} />
        </div>
    )
}

export default MyInfo;