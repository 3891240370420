/**
 * Main App
 */
import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {NotificationContainer} from "react-notifications";
import App from 'Container/App';
import {configureStore} from 'Store';

//css
import 'Assets/scss';

function MainApp() {
    return (
        <Provider store={configureStore()}>
            <NotificationContainer/>
            <Router>
                <App/>
            </Router>
        </Provider>
    )
}

export default MainApp;