import React from 'react';

function Breadcrumb({value}) {
    return (
        <div className={'d-flex flex-row justify-content-end'}>
            <span className={'breadcrumb'}>{value.join(' > ')}</span>
        </div>
    )
}

export default Breadcrumb;