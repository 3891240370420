import React, {useState, useEffect} from 'react';
import SideMenu from "Components/SideMenu";
import Breadcrumb from "Components/Breadcrumb";
import {Button, Pagination} from "@mui/material";
import moment from 'moment';
import * as Apis from 'Api';
import {useNavigate} from "react-router-dom";

function Knowledge() {
    const navigate = useNavigate();
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(9);

    useEffect(() => {
        getData();
    }, [currentPage]);

    const getData = () => {
        Apis.knowledgeList(currentPage, itemsPerPage).then((resp) => {
            setItemList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        });
    }

    const onClickItem = (itemId, index) => {
        navigate('/knowledge/view', {state: {side: '노동상식', itemIndex: index, itemList: itemList.map((v) => v.id)}});
    }

    const renderItem = (item, index) => {
        return (
            <div key={item.id} className={'knowledge-item'} onClick={() => onClickItem(item.id, index)}>
                <img src={Apis.imageBasePath + item.issueImage} alt={''}/>
                <div className={'knowledge-item-info'}>
                    <div className={'knowledge-title'}>
                        {item.issueTitle}
                    </div>
                    <div className={'knowledge-time'}>{moment(item.createdAt).format('YYYY-MM-DD')}</div>
                </div>
            </div>
        )
    }

    return (
        <div className={'page-center'}>
            <SideMenu title={'노동상식'} menu={[]}/>
            <div className={'page-content'}>
                <Breadcrumb value={['홈', '노동상식']}/>
                <span className={'fs-12 text-desc1'}>전체 {totalItemCount}</span>
                <div className={'knowledge-list'}>
                    {
                        itemList.map((v, i) => renderItem(v, i))
                    }
                </div>
                <div className={'d-flex justify-content-center mt-4'}>
                    <Pagination
                        color={'primary'}
                        shape={'rounded'}
                        count={totalPageCount}
                        page={currentPage}
                        onChange={(e, page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </div>
    )
}

export default Knowledge;