import React from 'react';
import SideMenu from "Components/SideMenu";
import Breadcrumb from "Components/Breadcrumb";
import {Button} from "@mui/material";
import {EmailOutlined} from "@mui/icons-material";

function Intro() {
    return (
        <div className={'page-center'}>
            <SideMenu title={'상담소 소개'} menu={[]}/>
            <div className={'page-content info-container'}>
                <Breadcrumb value={['홈', '상담소 소개']}/>
                <div className={'info-image'}>
                    <div className={'me-4'}><img src={require('Assets/img/intro_1.png')} alt={''} /></div>
                    <div className={'ms-4'}><img src={require('Assets/img/intro_2.png')} alt={''} /></div>
                </div>
                <div className={'d-flex flex-column justify-content-center mt-4 site-phone-info'}>
                    <div className={'phone-card-container'}>
                        <div className={'d-flex flex-column flex-md-row'}>
                            <div className={'phone-card'}>
                                <img src={require('Assets/img/icon_phone.svg').default} alt=''/>
                                <div className={'d-flex flex-column fs-16 ms-2'}>
                                    <span>구월노동법률상담소</span>
                                    <span className={'fw-bold'}>032-437-8501</span>
                                </div>
                            </div>
                            <div className={'phone-card'}>
                                <img src={require('Assets/img/icon_phone.svg').default} alt=''/>
                                <div className={'d-flex flex-column fs-16 ms-2'}>
                                    <span>서부노동법률상담소</span>
                                    <span className={'fw-bold'}>032-571-8508</span>
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex flex-column flex-md-row'}>
                            <div className={'phone-card'}>
                                <img src={require('Assets/img/icon_phone.svg').default} alt=''/>
                                <div className={'d-flex flex-column fs-16 ms-2'}>
                                    <span>남동근로자종합복지관</span>
                                    <span className={'fw-bold'}>032-818-1300</span>
                                </div>
                            </div>
                            <div className={'phone-card'}>
                                <img src={require('Assets/img/icon_phone.svg').default} alt=''/>
                                <div className={'d-flex flex-column fs-16 ms-2'}>
                                    <span>영종노동법률상담소</span>
                                    <span className={'fw-bold'}>032-746-8501</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'fs-13 text-center text-desc1 my-2'}>
                        평일 10:00 ~ 19:00(점심시간 12:00 ~ 13:00) 토요일, 공휴일 휴무
                    </div>
                    <div className={'email-btn pt-2'}>
                        <Button variant={'contained'} onClick={() => window.open('mailto:inochonglabor@naver.com')}>
                            <EmailOutlined/>
                            <span>이메일문의:  inochonglabor@naver.com</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro;