import React, {useState, useEffect} from 'react';
import {TextField, Button, Checkbox, FormControlLabel} from '@mui/material';
import {NavLink, useNavigate} from "react-router-dom";
import validator from "validator";
import classNames from "classnames";
import {NotificationManager} from "react-notifications";
import PrivacyModal from './PrivacyModal';
import * as Apis from 'Api';

function SignupPage() {
    const navigate = useNavigate();
    const [allowPrivacy, setAllowPrivacy] = useState(false);
    const [errorAllowPrivacy, setErrorAllowPrivacy] = useState(false);
    const [allowUsage, setAllowUsage] = useState(false);
    const [errorAllowUsage, setErrorAllowUsage] = useState(false);
    const [username, setUsername] = useState('');
    const [errorUsername, setErrorUsername] = useState(false);
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [password, setPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
    const [privacyModalType, setPrivacyModalType] = useState(null);


    const onKeyDown = (e) => {
        if(e.key === 'Enter') {
            onSignup();
        }
    }

    const checkValidate = () => {
        let valid = true;
        if(!allowPrivacy) {setErrorAllowPrivacy(true); valid = false}
        if(!allowUsage) {setErrorAllowUsage(true); valid = false}
        if(!username) {setErrorUsername(true); valid = false}
        if(!email || !validator.isEmail(email)) {setErrorEmail(true); valid = false}
        if(!password || !validator.isStrongPassword(password, {minLength: 6, minNumbers: 1, minUppercase: 0, minSymbols: 0})) {setErrorPassword(true); valid = false}
        if(!confirmPassword || password !== confirmPassword) {setErrorConfirmPassword(true); valid = false}
        return valid;
    }

    const onSignup = () => {
        if(!checkValidate()) return;
        Apis.signup({ username, email, password }).then((resp) => {
            NotificationManager.success('회원가입이 성공했습니다.');
            navigate('/login', {replace: true});
        }).catch(error => {
            NotificationManager.error(error.response?.data?.error?.message);
        }).finally(() => {
        });
    }

    return (
        <div className={'login-container d-flex justify-content-center'}>
            <div className={'signin-content'}>
                <div className={'page-title mb-4'}>회원가입</div>
                <div className={''}>약관동의</div>
                <div className={'d-flex flex-row justify-content-between'}>
                    <FormControlLabel
                        control={<Checkbox value={allowPrivacy} onChange={(e) => {setAllowPrivacy(e.target.checked); setErrorAllowPrivacy(false)}}/>}
                        label={<span className={classNames({'text-red': errorAllowPrivacy})} >개인정보처리방침<span className='text-main'>(필수)</span></span>}
                    />
                    <span
                        className={'btn-underline fs-16'}
                        onClick={() => setPrivacyModalType('privacy')}
                    >
                        자세히보기
                    </span>
                </div>
                <div className={'d-flex flex-row justify-content-between'}>
                    <FormControlLabel
                        control={<Checkbox value={allowUsage} onChange={(e) => {setAllowUsage(e.target.checked); setErrorAllowUsage(false)}}/>}
                        label={<span className={classNames({'text-red': errorAllowUsage})} >이용약관<span className='text-main'>(필수)</span></span>}
                    />
                    <span
                        className={'btn-underline fs-16'}
                        onClick={() => setPrivacyModalType('usage')}
                    >
                        자세히보기
                    </span>
                </div>
                <div className={'d-flex flex-column mb-4 mt-5'}>
                    <span>이름</span>
                    <TextField
                        variant="outlined"
                        placeholder={'ex) 홍길동'}
                        error={errorUsername}
                        value={username}
                        onChange={(e) => {setUsername(e.target.value); setErrorUsername(false)}}
                    />
                </div>
                <div className={'d-flex flex-column'}>
                    <span>아이디(이메일)</span>
                    <TextField
                        variant="outlined"
                        placeholder={'ex) email@naver.com'}
                        className={'mt-2 mb-2'}
                        error={errorEmail}
                        value={email}
                        onChange={(e) => {setEmail(e.target.value); setErrorEmail(false)}}
                    />
                </div>
                <span className={'mb-4 text-teal fs-14'}>*비밀번호 찾기에 이용되니 정확한 이메일 주소를 입력해주세요.</span>
                <div className={'d-flex flex-column mb-4'}>
                    <span>비밀번호</span>
                    <TextField
                        variant="outlined"
                        placeholder={'영문,숫자 조합 6자리 이상'}
                        className={'mt-2 mb-2'}
                        type={'password'}
                        error={errorPassword}
                        value={password}
                        onChange={(e) => {setPassword(e.target.value); setErrorPassword(false)}}
                    />
                </div>
                <div className={'d-flex flex-column mb-4'}>
                    <span>비밀번호 확인</span>
                    <TextField
                        variant="outlined"
                        placeholder={'영문,숫자 조합 6자리 이상'}
                        className={'mt-2 mb-2'}
                        onKeyDown={onKeyDown}
                        type={'password'}
                        error={errorConfirmPassword}
                        value={confirmPassword}
                        onChange={(e) => {setConfirmPassword(e.target.value); setErrorConfirmPassword(false)}}
                    />
                </div>
                <Button variant={'contained'} className={'py-3'} onClick={onSignup}>회원가입</Button>
            </div>
            {privacyModalType && <PrivacyModal open={true} onClose={() => setPrivacyModalType(null)} type={privacyModalType} /> }
        </div>
    )
}

export default SignupPage;