import React, {useState, useEffect} from 'react';
import Dialog from 'Components/Dialog';
import {TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateUserInfo} from 'Store/Actions';
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

function ModalUsername({open, onClose}) {
    const dispatch = useDispatch();
    const authUser = useSelector(state => state.authUser);
    const [username, setUsername] = useState(authUser.userInfo?.username);

    useEffect(() => {
        setUsername(authUser.userInfo?.username);
    }, [open]);

    const onUpdatePassword = () => {
        if(!username) return;
        Apis.setUserInfo(username).then((resp) => {
            return Apis.getUserInfo();
        }).then((resp) => {
            dispatch(updateUserInfo(resp));
            NotificationManager.success('이름이 변경되었습니다.');
            onClose();
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        });
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={'이름변경'}
            confirmLabel={'저장'}
            onConfirm={onUpdatePassword}
        >
            <div className={'d-flex flex-column mb-2'}>
                <span className={'fs-14 mb-1'}>이름</span>
                <TextField
                    variant="outlined"
                    className={'fs-14'}
                    placeholder={'ex) 홍길동'}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </div>
        </Dialog>
    )
}

export default ModalUsername;

