/**
 * Auth User Reducers
 */
import { Cookies } from 'react-cookie';
import {
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    UPDATE_USER_INFO,
    UPDATE_COMPANY,
    UPDATE_SITE_SETTING,
} from 'Store/Actions/types';

const cookie = new Cookies();

/**
 * initial auth user
 */

const INIT_STATE = {
    userInfo: cookie.get("user_info") ? cookie.get("user_info") : {},
    accessToken: cookie.get("access_token"),
    loading: false,
    isLogin: !!cookie.get("access_token"),
    companyList: cookie.get("company_list") ? cookie.get("company_list") : [],
    siteSetting: {}
};

let authUser = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                userInfo: action.payload.info,
                accessToken: action.payload.accessToken,
                isLogin: true,
            };
        case UPDATE_USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            }
        case LOGOUT_USER:
            return { ...state, accessToken: '', isLogin: false };
        case UPDATE_COMPANY:
            return {...state, companyList: action.payload}
        case UPDATE_SITE_SETTING:
            return {...state, siteSetting: action.payload}
        default: return { ...state };
    }
}

export default authUser;