import React, {useState, useEffect} from 'react';
import {TextField} from "@mui/material";
import Dialog from "../../Components/Dialog";
import validator from "validator";
import * as Apis from 'Api';
import {changeUserPassword} from "Api";
import {NotificationManager} from "react-notifications";

function ModalPassword({open, onClose}) {
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);

    useEffect(() => {
        setOldPassword('');
        setPassword('');
        setErrorPassword(false);
        setConfirmPassword('')
        setErrorConfirmPassword(false);
    }, [open]);

    const onKeyDown = (e) => {
        if(e.key === 'Enter') {
            onResetPassword();
        }
    }

    const onResetPassword = () => {
        if(!password || !validator.isStrongPassword(password, {minLength: 6, minNumbers: 1, minUppercase: 0, minSymbols: 0})) {setErrorPassword(true); return;}
        if(!confirmPassword || password !== confirmPassword) {setErrorConfirmPassword(true); return;}
        Apis.changeUserPassword(oldPassword, password).then((resp) => {
            if(resp.result !== 'failed') {
                NotificationManager.success('비밀번호가 변경되었습니다.');
                onClose();
            } else {
                NotificationManager.error(resp.msg);
            }
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        });
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={'비밀번호 변경'}
            confirmLabel={'저장'}
            onConfirm={onResetPassword}
        >
            <div className={'d-flex flex-column mb-4 fs-14'}>
                <span>현재 비밀번호</span>
                <TextField
                    variant="outlined"
                    placeholder={'현재 비밀번호를 입력하세요'}
                    className={'mt-2 mb-2'}
                    type={'password'}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
            </div>
            <div className={'d-flex flex-column mb-4 fs-14'}>
                <span>새 비밀번호</span>
                <TextField
                    variant="outlined"
                    placeholder={'영문,숫자 조합 6자리 이상'}
                    className={'mt-2 mb-2'}
                    type={'password'}
                    error={errorPassword}
                    value={password}
                    onChange={(e) => {setPassword(e.target.value); setErrorPassword(false)}}
                />
            </div>
            <div className={'d-flex flex-column mb-4 fs-14'}>
                <span>새 비밀번호 확인</span>
                <TextField
                    variant="outlined"
                    placeholder={'영문,숫자 조합 6자리 이상'}
                    className={'mt-2 mb-2'}
                    onKeyDown={onKeyDown}
                    type={'password'}
                    error={errorConfirmPassword}
                    value={confirmPassword}
                    onChange={(e) => {setConfirmPassword(e.target.value); setErrorConfirmPassword(false)}}
                />
            </div>
        </Dialog>
    )
}

export default ModalPassword;

