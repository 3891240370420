/**
 * App Reducers
 */
import { combineReducers } from 'redux';
import authUserReducer from './AuthUserReducer';

const Reducers = combineReducers({
    authUser: authUserReducer,
});

export default Reducers;