import React, {useState, useEffect} from 'react';
import SideMenu from "Components/SideMenu";
import Breadcrumb from "Components/Breadcrumb";
import {Button, MenuItem, Pagination, Select, TextField} from "@mui/material";
import {LockOutlined} from "@mui/icons-material";
import moment from 'moment';
import * as Apis from "../../Api";
import {useNavigate} from "react-router-dom";
import {NotificationManager} from "react-notifications";

function Board() {
    const navigate = useNavigate();
    const [itemList, setItemList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQnaConsulting, setSearchQnaConsulting] = useState(null);
    const [searchQnaStatus, setSearchQnaStatus] = useState(null);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        getData();
    }, [currentPage]);

    const getData = () => {
        const search = {
            qnaStatus: searchQnaStatus ? searchQnaStatus : undefined,
            qnaConsulting: searchQnaConsulting ? searchQnaConsulting : undefined,
            text: searchText ? searchText : undefined,
        };

        Apis.qnaList(currentPage, itemsPerPage, search).then((resp) => {
            setItemList(resp.data);
            setCurrentPage(resp.meta.currentPage);
            setTotalPageCount(resp.meta.totalPageCount);
            setTotalItemCount(resp.meta.totalItemCount);
            setItemsPerPage(resp.meta.itemsPerPage);
        }).catch((e) => {
            console.error(e);
        });
    }

    const onClickItem = (item) => {
        if (!item.qnaPublic) {
            NotificationManager.error('비공개 상담내역입니다.');
        } else {
            const publicIdList = itemList.filter((v) => v.qnaPublic).map((v) => v.id);
            const index = publicIdList.findIndex((v) => v === item.id);
            navigate('/board/view', {state: {itemIndex: index, itemList: publicIdList}});
        }
    }

    const renderItem = (item, index) => {
        return (
            <div className={'board-qna-item'} key={item.id} onClick={() => onClickItem(item)}>
                <div>{(currentPage - 1) * itemsPerPage + index + 1}</div>
                <div>{Apis.values.qnaConsultingType[item.qnaConsulting]}</div>
                <div className={'text-start fw-semi-bold'}>{item.qnaTitle} {!item.qnaPublic && <LockOutlined fontSize={'small'} color={'action'}/>}</div>
                <div>
                    {
                        item.qnaStatus === 'PENDING' ?
                            <span className={'status'}>답변대기</span> :
                            <span className={'status finish'}>답변완료</span>
                    }
                </div>
                <div>{moment(item.createdAt).format('YYYY-MM-DD')}</div>
            </div>
        )
    }

    return (
        <div className={'page-center'}>
            <SideMenu title={'상담게시판'} menu={[]}/>
            <div className={'page-content board-container'}>
                <Breadcrumb value={['홈', '상담게시판']}/>
                <div className={'board-top-info'}>
                    <div className={'fs-18 fw-bold'}>
                        <span className={'text-main'}>[온라인상담]</span>
                        <span className={'text-desc'}>노동자상담</span>
                    </div>
                    <div className={'d-flex flex-column text-desc fs-16 p-2'}>
                        <div>• 정확한 상담내용을 작성하지 않으실 경우 답변처리가 안 되는 점 양해바랍니다.</div>
                        <div>• 상담내용에 맞는 자료로 첨부파일을 업로드해주시길 바랍니다. </div>
                        <div>• 상담은 상담일 뿐 개인의 노동문제를 해결해드리지 않습니다.</div>
                        <div>• 법률적 사항을 넘어서는 상담은 불가능합니다.</div>
                    </div>
                    <div className={'qna-info-bar mt-1'}>
                        <div className={'qna-info-desc'}>궁금증이 해결되지 않았나요? 직접 문의하세요</div>
                        <Button variant={'contained'} className={'ms-4'} size={'large'} onClick={() => navigate('/profile/my-qna')}>
                            <img src={require('Assets/img/icon_qna.svg').default} alt=''/>
                            <span className={'fs-20 fw-semi-bold'}>무료 상담하기</span>
                        </Button>
                    </div>
                </div>
                <div className={'board-search d-flex flex-column flex-md-row '}>
                    <div className={'d-flex flex-row'}>
                        <div className={'me-2'}>
                            <Select
                                value={searchQnaConsulting}
                                onChange={(e) => setSearchQnaConsulting(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value={null} style={{display: 'none'}}>상담유형</MenuItem>
                                <MenuItem value="">전체</MenuItem>
                                {
                                    Object.keys(Apis.values.qnaConsultingType).map((v) => <MenuItem value={v} key={v}>{Apis.values.qnaConsultingType[v]}</MenuItem>)
                                }
                            </Select>
                        </div>
                        <div className={'me-2'}>
                            <Select
                                value={searchQnaStatus}
                                onChange={(e) => setSearchQnaStatus(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value={null} style={{display: 'none'}}>답변상태</MenuItem>
                                <MenuItem value="">전체</MenuItem>
                                {
                                    Object.keys(Apis.values.qnaStatus).map((v) => <MenuItem value={v} key={v}>{Apis.values.qnaStatus[v]}</MenuItem>)
                                }
                            </Select>
                        </div>
                    </div>
                    <div className={'d-flex flex-row flex-fill'}>
                        <TextField
                            variant="outlined"
                            placeholder={'제목'}
                            className={'flex-fill me-2'}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Button variant={'contained'} size={'large'} onClick={getData}>검색</Button>
                    </div>
                </div>
                <div className={'fs-12 text-desc1 mt-3 mb-2'}>전체 {totalItemCount}건</div>
                <div className={'board-qna-list'}>
                    <div className={'board-qna-item header'}>
                        <div>번호</div>
                        <div>상담유형</div>
                        <div>제목</div>
                        <div><span className={'status'}>답변상태</span></div>
                        <div>등록일</div>
                    </div>
                    {
                        itemList.map((v, i) => renderItem(v, i))
                    }
                </div>
                <div className={'d-flex justify-content-center mt-4'}>
                    <Pagination
                        color={'primary'}
                        shape={'rounded'}
                        count={totalPageCount}
                        page={currentPage}
                        onChange={(e, page) => setCurrentPage(page)}
                    />
                </div>
            </div>

        </div>
    )
}

export default Board;