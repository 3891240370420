 import React, {useState, useEffect} from 'react';
import {NavLink, useNavigate, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import classNames from "classnames";
import {isMobile, BrowserView, MobileView} from "react-device-detect";
import {IconButton} from '@mui/material';
import {MenuOutlined, Close, Person} from '@mui/icons-material';
import {logoutUser} from "Store/Actions";
import * as Apis from 'Api';

const headerMenuList = [
    {label: '상담소 소개', link: '/intro'},
    {label: '상담절차', link: '/step'},
    {label: '노동상식', link: '/knowledge'},
    {label: '노동이슈', link: '/issue'},
    {label: '상담게시판', link: '/board'},
    {label: '법률정보', link: '/law-info'},
]

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const authUser = useSelector(state => state.authUser);
    const siteSetting = useSelector(state => state.authUser.siteSetting);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const {isLogin} = authUser;

    useEffect(() => {
        setShowMobileMenu(false);
    }, [location]);

    const onLogout = () => {
        Apis.logout().then(() => {
        }).finally(() => {
            dispatch(logoutUser());
            navigate('/');
        });
    }

    const onClickHeader = (item) => {
        if(location.pathname.indexOf(item.link) === 0 && location.pathname.indexOf(item.link + '/view') === 0) {
            navigate(item.link, {replace: true});
        } else {
            navigate(item.link);
        }
    }

    const renderHeaderItem = (item, index) => {
        const isCurrentPage = location.pathname.indexOf(item.link) === 0;
        return (
            <span
                key={index}
                className={classNames({'active': isCurrentPage})}
                onClick={() => onClickHeader(item)}
            >
                {item.label}
            </span>
        )
    }

    const renderPc = () => {
        return (
            <header className={'site-header'}>
                <div className="header-info d-flex flex-row">
                    <div className={'header-icons d-flex flex-row'}>
                        <a href={siteSetting['블로그']} target='_blank'><img src={require("Assets/img/icon_blog.svg").default} alt=""/></a>
                        <a href={siteSetting['인스타']} target='_blank'><img src={require("Assets/img/icon_instagram.svg").default} alt=""/></a>
                        <a href={siteSetting['유투브']} target='_blank'><img src={require("Assets/img/icon_youtube.svg").default} alt=""/></a>
                        <a href='https://comic.naver.com/challenge/list?titleId=773963' target='_blank'><img src={require("Assets/img/icon_webtoon.svg").default} alt=""/></a>
                    </div>
                    <a href='/'><img className={'header-logo'} src={require("Assets/img/logo1.svg").default} alt=""/></a>
                    <div className={'header-user d-flex flex-row'}>
                        {
                            isLogin ?
                                <div className={'d-flex flex-row align-items-center'}>
                                    <div className={'text-desc fs-14 fw-semi-bold'}>{authUser.userInfo?.username}님</div>
                                    <span onClick={() => navigate('/profile')}>마이페이지</span>
                                    <span className={'divider'}/>
                                    <span onClick={onLogout}>로그아웃</span>
                                </div> :
                                <div className={'d-flex flex-row align-items-center'}>
                                    <span onClick={() => navigate('/login')}>로그인</span>
                                    <span className={'divider'}/>
                                    <span onClick={() => navigate('/signup')}>회원가입</span>
                                </div>
                        }
                    </div>
                </div>
                <div className='header-menu'>
                    <div className={'d-flex flex-row'}>
                        {
                            headerMenuList.map((v, i) => renderHeaderItem(v, i))
                        }
                    </div>
                </div>
            </header>
        )
    }

    const renderMobile = () => {
        return (
            <header className={'site-mobile-header'}>
                <div className={'header-bar'}>
                    <div>
                        <IconButton color={'primary'} onClick={() => setShowMobileMenu(!showMobileMenu)}><MenuOutlined/></IconButton>
                        <a href='/'><img src={require('Assets/img/logo1.svg').default} className={'ms-2'} height={35} alt='' /></a>
                    </div>
                    {
                        showMobileMenu && <IconButton onClick={() => setShowMobileMenu(false)}><Close/></IconButton>
                    }
                </div>
                <div className={'m-header-content ' + classNames({'hide': !showMobileMenu})}>
                    <div className={'m-header-top'}>
                        <div className={'m-header-user'}>
                            <div><img src={require('Assets/img/icon_m_user.svg').default} alt={''} width={32}/></div>
                            <span>{isLogin ? authUser.userInfo?.username + '님' : '로그인이 필요합니다.'}</span>
                        </div>
                        <div className={'fs-14 mt-3'}>
                            {
                                isLogin ?
                                    <div className={'d-flex flex-row align-items-center'}>
                                        <span onClick={() => navigate('/profile')}>마이페이지</span>
                                        <span className={'divider mx-3'}/>
                                        <span onClick={onLogout}>로그아웃</span>
                                    </div> :
                                    <div className={'d-flex flex-row align-items-center'}>
                                        <span onClick={() => navigate('/login')}>로그인</span>
                                        <span className={'divider mx-3'}/>
                                        <span onClick={() => navigate('/signup')}>회원가입</span>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className={'m-header-menu'}>
                        {
                            headerMenuList.map((v, i) => renderHeaderItem(v, i))
                        }
                    </div>
                    <div className={'m-header-bottom'}>
                        <a href={siteSetting['블로그']}><img src={require("Assets/img/icon_blog.svg").default} alt=""/></a>
                        <a href={siteSetting['인스타']}><img src={require("Assets/img/icon_instagram.svg").default} alt=""/></a>
                        <a href={siteSetting['유투브']}><img src={require("Assets/img/icon_youtube.svg").default} alt=""/></a>
                        <a href='https://comic.naver.com/challenge/list?titleId=773963'><img src={require("Assets/img/icon_webtoon.svg").default} alt=""/></a>
                    </div>
                </div>
            </header>
        )
    }

    if(isMobile) {
        return renderMobile();
    } else {
        return renderPc();
    }

}

export default Header;