import React, {useState, useEffect} from 'react';
import Dialog from "../../Components/Dialog";
import {updateCompanyList} from "../../Store/Actions";
import {useDispatch, useSelector} from "react-redux";

function ModalCompanyDel({open, onClose, delIndex}) {
    const dispatch = useDispatch();
    const companyList = useSelector(state => state.authUser.companyList);

    const onDeleteCompany = () => {
        const newList = [...companyList];
        newList.splice(delIndex, 1);
        if (newList.every((v) => !v.isDefault) && newList.length > 0) {
            newList[0].isDefault = true;
        }
        dispatch(updateCompanyList(newList));
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={'직장정보 삭제'}
            confirmLabel={'삭제'}
            onConfirm={onDeleteCompany}
        >
            <div className={'d-flex flex-column mb-2'}>
                직장 정보를 정말로 삭제할까요?
            </div>
        </Dialog>
    )
}

export default ModalCompanyDel;

