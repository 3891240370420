import React, {useState, useEffect} from 'react';
import Header from './Header';
import Footer from './Footer';
import {useDispatch} from "react-redux";
import {updateSiteSetting} from 'Store/Actions';
import * as Apis from 'Api';

const AppLayout = ({children}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        Apis.linkList().then((resp) => {
            dispatch(updateSiteSetting(resp));
        })
    }, []);

    return (
        <div className={'d-flex flex-column'}>
            <Header />
            <div className="">
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default AppLayout;