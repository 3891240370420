import React, {useState, useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, InputAdornment, TextField} from "@mui/material";
import {ArrowForwardIosRounded, LockOutlined, Search} from '@mui/icons-material';
import classNames from "classnames";
import * as Apis from 'Api';
import moment from "moment";

function SearchPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [issueList, setIssueList] = useState([]);
    const [knowledgeList, setKnowledgeList] = useState([]);
    const [qnaList, setQnaList] = useState([]);
    const [lawInfoList, setLawInfoList] = useState([]);
    const [selectQnaFilter, setSelectQnaFilter] = useState("all");
    const [showType, setShowType] = useState("all");

    useEffect(() => {
        const search = searchParams.get('text');
        setSearchText(search);
        getData(search)
    }, []);

    const getData = (search) => {
        Apis.searchData(search).then((resp) => {
            setIssueList(resp.issueList);
            setKnowledgeList(resp.knowledgeList);
            setQnaList(resp.qnaList);
            setLawInfoList(resp.lawInfoList);
        });
    }

    const onSearch = (e) => {
        if (e.key === 'Enter') {
            const newQueryParameters = new URLSearchParams();
            newQueryParameters.set('text',  searchText);
            setSearchParams(newQueryParameters);
            getData(searchText);
        }
    }

    const onShowIssue = (itemId, side, link) => {
        navigate(link, {state: {side: side, itemIndex: 0, itemList: [itemId]}});
    }

    const onShowQna = (itemId) => {
        navigate('/board/view', {state: {itemIndex: 0, itemList: [itemId]}});
    }

    const renderIssueItem = (item, side, link) => {
        return (
            <div key={item.id} className={'d-flex flex-row justify-content-between py-3 border-bottom cursor-pointer'} onClick={() => onShowIssue(item.id, side, link)}>
                <div>
                    <div className={'text-desc fs-16'}>{item.issueTitle}</div>
                    <div className={'text-desc1 fs-14'}>{item.issueContent}...</div>
                </div>
                <img className={'search-issue-item-img'} src={Apis.imageBasePath + item.issueImage} alt={''}/>
            </div>
        )
    }

    const renderQnaItem = (item) => {
        return (
            <div className={'qna-history-line px-0'} key={item.id} onClick={() => onShowQna(item.id)}>
                <div className={'history-info'}>
                    {
                        item.qnaStatus === 'PENDING' ?
                            <span className={'history-status'}>답변대기</span> :
                            <span className={'history-status finish'}>답변완료</span>
                    }
                    <span className={'history-title'}>[{Apis.values.qnaConsultingType[item.qnaConsulting]}]{item.qnaTitle} {!item.qnaPublic &&
                        <LockOutlined fontSize={'small'} color={'action'}/>}</span>
                    <span className={'history-content'}>{item.qnaContent}</span>
                </div>
                <span className={'history-time'}>{moment(item.createdAt).format('YYYY-MM-DD')}</span>
            </div>
        )
    }

    const renderQnaFilterItem = ({value, label}) => {
        return (
            <Button key={value} className={'my-3 me-2 rounded-5'} onClick={() => setSelectQnaFilter(value)} variant={'contained'} color={selectQnaFilter === value ? 'info' : 'inherit'}>
                {label}
            </Button>
        )
    };

    const renderKnowledgeList = () => {
        return (
            <div>
                <div className={'d-flex flex-row justify-content-between align-items-end border-bottom pb-1'}>
                    <span className={'fs-18 fw-bold text-desc'}>노동상식</span>
                    <span className={'fs-14 text-desc1'}>검색결과 {knowledgeList.length}</span>
                </div>
                {
                    (showType === "all" ? [...knowledgeList].splice(0, 10) : knowledgeList).map((v) => renderIssueItem(v, '노동상식', '/knowledge/view'))
                }
                {
                    (showType === "all" && knowledgeList.length > 10) &&
                    <div className={'d-flex flex-row justify-content-center align-items-center text-desc1 border-bottom py-2'}>
                        <div className={'search-all-btn'} onClick={() => setShowType('knowledge')}>
                            <span className={'fs-16 me-2'}>전체보기</span> <ArrowForwardIosRounded fontSize={'small'} style={{fontSize: 14}}/>
                        </div>
                    </div>
                }
            </div>
        )
    }


    const renderIssueList = () => {
        return (
            <div>
                <div className={'d-flex flex-row justify-content-between align-items-end border-bottom pb-1 mt-5'}>
                    <span className={'fs-18 fw-bold text-desc'}>노동이슈</span>
                    <span className={'fs-14 text-desc1'}>검색결과 {issueList.length}</span>
                </div>
                {
                    (showType === "all" ? [...issueList].splice(0, 10) : issueList).map((v) => renderIssueItem(v, '노동이슈', '/issue/view'))
                }
                {
                    (showType === "all" && issueList.length > 10) &&
                    <div className={'d-flex flex-row justify-content-center align-items-center text-desc1 border-bottom py-2'}>
                        <div className={'search-all-btn'} onClick={() => setShowType('issue')}>
                            <span className={'fs-16 me-2'}>전체보기</span> <ArrowForwardIosRounded fontSize={'small'} style={{fontSize: 14}}/>
                        </div>
                    </div>
                }
            </div>
        )
    }
    const renderQnaList = () => {
        return (
            <div>
                <div className={'d-flex flex-row justify-content-between align-items-end border-bottom pb-1 mt-5'}>
                    <span className={'fs-18 fw-bold text-desc'}>상담 사례</span>
                    <span className={'fs-14 text-desc1'}>검색결과 {qnaList.length}</span>
                </div>
                {
                    showType === "qna" &&
                    <div className={'qna-filter-items'}>
                        <div>
                        {
                            [
                                {value: "all", label: '전체'},
                                {value: "DELAY_WAGE", label: '임금체불'},
                                {value: "UNFAIR_DISMISSAL", label: '부당해고'},
                                {value: "NON_REGULAR", label: '비정규직'},
                                {value: "LEGAL", label: '생활법률'},
                                {value: "OTHER", label: '기타'}].map((v) => renderQnaFilterItem(v))
                        }
                        </div>
                    </div>
                }
                {
                    (showType === "all" ? [...qnaList].splice(0, 10) : (selectQnaFilter === 'all' ? qnaList : qnaList.filter((v) => v.qnaConsulting === selectQnaFilter))).map((v) => renderQnaItem(v))
                }
                {
                    (showType === "all" && qnaList.length > 10) &&
                    <div className={'d-flex flex-row justify-content-center align-items-center text-desc1 border-bottom py-2'}>
                        <div className={'search-all-btn'} onClick={() => setShowType('qna')}>
                            <span className={'fs-16 me-2'}>전체보기</span> <ArrowForwardIosRounded fontSize={'small'} style={{fontSize: 14}}/>
                        </div>
                    </div>
                }
            </div>
        )
    }

    const renderLawInfoList = () => {
        return (
            <div>
                <div className={'d-flex flex-row justify-content-between align-items-end border-bottom pb-1 mt-5'}>
                    <span className={'fs-18 fw-bold text-desc'}>법률정보</span>
                    <span className={'fs-14 text-desc1'}>검색결과 {lawInfoList.length}</span>
                </div>
                {
                    (showType === "all" ? [...lawInfoList].splice(0, 10) : lawInfoList).map((v) => renderIssueItem(v, '법률정보', '/law-info/view'))
                }
                {
                    (showType === "all" && lawInfoList.length > 10) &&
                    <div className={'d-flex flex-row justify-content-center align-items-center text-desc1 border-bottom py-2'}>
                        <div className={'search-all-btn'} onClick={() => setShowType('law_info')}>
                            <span className={'fs-16 me-2'}>전체보기</span> <ArrowForwardIosRounded fontSize={'small'} style={{fontSize: 14}}/>
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <div className={'page-center search-container'}>
            <div className={'search-input'}>
                <TextField
                    variant="outlined"
                    placeholder={'검색'}
                    className={'mt-3 mb-2'}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Search fontSize={'large'}/></InputAdornment>,
                    }}
                    onKeyDown={onSearch}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <div className={'search-result'}>
                <span className={classNames({'text-desc': showType === 'all'})} onClick={() => setShowType('all')}>
                    전체({knowledgeList.length + issueList.length + qnaList.length + lawInfoList.length})
                </span>
                <div/>
                <span className={classNames({'text-desc': showType === 'knowledge'})} onClick={() => setShowType('knowledge')}>노동상식({knowledgeList.length})</span>
                <div/>
                <span className={classNames({'text-desc': showType === 'issue'})} onClick={() => setShowType('issue')}>노동이슈({issueList.length})</span>
                <div/>
                <span className={classNames({'text-desc': showType === 'qna'})} onClick={() => setShowType('qna')}>상담사례({qnaList.length})</span>
                <div/>
                <span className={classNames({'text-desc': showType === 'law_info'})} onClick={() => setShowType('law_info')}>법률정보({lawInfoList.length})</span>
            </div>
            {(showType === "all" || showType === "knowledge") && renderKnowledgeList()}
            {(showType === "all" || showType === "issue") && renderIssueList()}
            {(showType === "all" || showType === "qna") && renderQnaList()}
            {(showType === "all" || showType === "law_info") && renderLawInfoList()}
        </div>
    )
}

export default SearchPage;