import React, {useState} from 'react';
import {Button} from "@mui/material";
import SideMenu from "Components/SideMenu";
import Breadcrumb from "Components/Breadcrumb";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";

function Step() {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);

    const renderStep0 = () => {
        if(!isMobile) {
            return (<img src={require('Assets/img/step/tab1.png')} alt=''/>)
        } else {
            return (<img src={require('Assets/img/step/mobile_tab1.png')} alt=''/>)
        }
    }

    const renderStep1 = () => {
        if(!isMobile) {
            return (<img src={require('Assets/img/step/tab2.png')} alt=''/>)
        } else {
            return (<img src={require('Assets/img/step/mobile_tab2.png')} alt=''/>)
        }
    }

    const renderStep2 = () => {
        if(!isMobile) {
            return (<img src={require('Assets/img/step/tab3.png')} alt=''/>)
        } else {
            return (<img src={require('Assets/img/step/mobile_tab3.png')} alt=''/>)
        }
    }

    return (
        <div className={'page-center'}>
            <SideMenu title={'상담절차'} menu={[]}/>
            <div className={'page-content step-container'}>
                <Breadcrumb value={['홈', '상담절차']}/>
                <div className={'step-info'}>
                    <div className={'step-info-tabs'}>
                        <Button variant={tabIndex === 0 ? 'contained' : 'outlined'} onClick={() => setTabIndex(0)}>
                            <span>노동상담</span>
                        </Button>
                        <Button variant={tabIndex === 1 ? 'contained' : 'outlined'} onClick={() => setTabIndex(1)}>
                            <span>임금체불</span>
                            <span className={'fs-18'}>권리구제 절차 안내</span>
                        </Button>
                        <Button variant={tabIndex === 2 ? 'contained' : 'outlined'} onClick={() => setTabIndex(2)}>
                            <span>부당해고</span>
                            <span className={'fs-18'}>권리구제 절차 안내</span>
                        </Button>
                    </div>
                    <div className={'step-info-img'}>
                        {
                            tabIndex === 0 ? renderStep0() : (tabIndex === 1 ? renderStep1() : renderStep2())
                        }
                    </div>
                </div>
                <div className={'qna-info-bar mt-4'}>
                    <div className={'qna-info-desc'}>궁금증이 해결되지 않았나요? 직접 문의하세요</div>
                    <Button variant={'contained'} className={'ms-4'} size={'large'} onClick={() => navigate('/profile/my-qna')}>
                        <img src={require('Assets/img/icon_qna.svg').default} alt=''/>
                        <span className={'fs-20 fw-semi-bold'}>무료 상담하기</span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Step;