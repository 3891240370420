import React, {useEffect} from 'react';
import {useNavigate, useParams, useLocation, useSearchParams} from "react-router-dom";
import * as Apis from 'Api';
import {useDispatch} from "react-redux";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {signinUser} from "../../Store/Actions";

function ThirdLogin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {type} = useParams();
    const [searchParams] = useSearchParams();

    useEffect( () => {
        getThirdInfo();
    }, []);

    const getThirdInfo = async () => {
        try {
            let data;
            if (type === 'naver') {
                const token = location.hash.split('=')[1].split('&')[0];
                data = {accessToken: token};
            } else {
                navigate('/login', {replace: true});
                return;
            }
            console.log(type, data);
            const result = await Apis.login(null, null, data)
            dispatch(signinUser(result.info, result.token, navigate));
        } catch (e) {
            NotificationManager.error('로그인이 실패했습니다.');
            navigate('/login', {replace: true});
        }
    }

    return (
        <div className={'third-login-container'}>
            <img src={require('Assets/img/icon_naver_circle.png')} alt={''} width={35}/>
            <span className={'ms-3'}>네이버 로그인중입니다...</span>
        </div>
    )
}

export default ThirdLogin