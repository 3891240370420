import React, {useState, useEffect, useRef} from 'react';
import {Radio, RadioGroup, FormControlLabel, TextField, Select, MenuItem, Button, IconButton} from '@mui/material';
import {Close} from '@mui/icons-material';
import SideMenu from "Components/SideMenu";
import Breadcrumb from "Components/Breadcrumb";
import {useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import JSONParseDefault from "json-parse-default";
import * as Apis from 'Api';
import {NotificationManager} from "react-notifications";

function MyQnaEdit() {
    const param = useParams();
    const navigate = useNavigate();
    const inputFile = useRef(null)
    const companyList = useSelector(state => state.authUser.companyList);
    const [qnaPublic, setQnaPublic] = useState(true);
    const [qnaTitle, setQnaTitle] = useState("");
    const [qnaResidentialArea, setQnaResidentialArea] = useState('');
    const [qnaSex, setQnaSex] = useState('');
    const [qnaAge, setQnaAge] = useState('');
    const [qnaWorkType, setQnaWorkType] = useState("WORKER");
    const [qnaContractTerm, setQnaContractTerm] = useState("REGULAR");
    const [qnaContractPeriod, setQnaContractPeriod] = useState("");
    const [qnaWorkerNum, setQnaWorkerNum] = useState("OVER_5");
    const [qnaWorkRange, setQnaWorkRange] = useState("");
    const [qnaWorkTime, setQnaWorkTime] = useState("");
    const [qnaBusinessPlace, setQnaBusinessPlace] = useState('');
    const [qnaBusinessType, setQnaBusinessType] = useState('');
    const [qnaPhone, setQnaPhone] = useState("");
    const [qnaConsulting, setQnaConsulting] = useState("DELAY_WAGE");
    const [qnaContent, setQnaContent] = useState("");
    const [qnaStatus, setQnaStatus] = useState("PENDING");
    const [selectCompanyIndex, setSelectCompanyIndex] = useState('new');
    const [qnaFiles, setQnaFiles] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        if (param.id) {
            Apis.qnaInfo(param.id).then((resp) => {
                setQnaPublic(resp.qnaPublic);
                setQnaTitle(resp.qnaTitle);
                setQnaResidentialArea(resp.qnaResidentialArea);
                setQnaSex(resp.qnaSex);
                setQnaAge(resp.qnaAge);
                setQnaWorkType(resp.qnaWorkType);
                setQnaContractTerm(resp.qnaContractTerm);
                setQnaContractPeriod(resp.qnaContractPeriod);
                setQnaWorkerNum(resp.qnaWorkerNum);
                setQnaWorkRange(resp.qnaWorkRange);
                setQnaWorkTime(resp.qnaWorkTime);
                setQnaBusinessPlace(resp.qnaBusinessPlace);
                setQnaBusinessType(resp.qnaBusinessType);
                setQnaPhone(resp.qnaPhone);
                setQnaConsulting(resp.qnaConsulting);
                setQnaContent(resp.qnaContent);
                setQnaStatus(resp.qnaStatus);
                setQnaFiles(JSONParseDefault(resp.qnaFiles, null, []));
            }).catch(() => {
            });
        } else {
            // const defaultCompanyIndex = companyList ? companyList.findIndex((v) => v.isDefault) : -1;
            // if (companyList.length > 0 && defaultCompanyIndex !== -1) {
            //     setSelectCompanyIndex(defaultCompanyIndex);
            //     onChangeCompany(defaultCompanyIndex);
            // }
        }
    };

    const checkValidate = () => {
        let valid = true;
        if (qnaTitle === '') valid = false;
        if (!qnaResidentialArea || !qnaAge || !qnaSex || !qnaBusinessPlace || !qnaBusinessType) valid = false;
        if (qnaWorkType === 'WORKER' || qnaWorkType === 'FREELANCER') {
            if (qnaWorkRange === '') valid = false;
            if (qnaWorkTime === '') valid = false;
        }
        if (qnaContent === '') valid = false;
        return valid;
    };

    const onChangeCompany = (index) => {
        setSelectCompanyIndex(index);
        if (index === "new") {
            setQnaWorkType("WORKER");
            setQnaContractTerm("REGULAR");
            setQnaContractPeriod("");
            setQnaWorkerNum("OVER_5");
            setQnaWorkRange("");
            setQnaWorkTime("");
        } else {
            setQnaWorkType(companyList[index].qnaWorkType);
            setQnaContractTerm(companyList[index].qnaContractTerm);
            setQnaContractPeriod(companyList[index].qnaContractPeriod);
            setQnaWorkerNum(companyList[index].qnaWorkerNum);
            setQnaWorkRange(companyList[index].qnaWorkRange);
            setQnaWorkTime(companyList[index].qnaWorkTime);
        }
    };

    const onSave = async () => {
        if (!checkValidate()) {
            NotificationManager.error('모든 정보를 입력하세요.');
            return;
        }
        try {
            const data = {
                qnaPublic,
                qnaTitle,
                qnaResidentialArea,
                qnaSex,
                qnaAge,
                qnaWorkType,
                qnaContractTerm,
                qnaContractPeriod,
                qnaWorkerNum,
                qnaWorkRange,
                qnaWorkTime,
                qnaBusinessPlace,
                qnaBusinessType,
                qnaPhone,
                qnaConsulting,
                qnaContent,
            };
            const needUploadFiles = qnaFiles.filter((v) => !(v && v.path));
            if (needUploadFiles.length > 0) {
                const formData = new FormData();
                needUploadFiles.forEach((v, i) => {
                    formData.append(i.toString(), v);
                })
                const resp = await Apis.qnaUploadFile(formData);
                if (!!resp) {
                    const files = qnaFiles.filter((v) => (v && v.path));
                    data.qnaFiles = JSON.stringify(files.concat(resp));
                }
            } else {
                data.qnaFiles = JSON.stringify([]);
            }
            if (!param.id) {
                await Apis.qnaAdd(data);
            } else {
                await Apis.qnaUpdate(param.id, data);
            }
            navigate('/profile/my-qna-history')
        } catch (e) {
            NotificationManager.error('오류가 발생했습니다.');
        }
    };

    const onSelectFile = (e) => {
        setQnaFiles([...qnaFiles, e.target.files[0]]);
    }

    const onRemoveFile = (index) => {
        qnaFiles.splice(index, 1);
        setQnaFiles([...qnaFiles]);
    }

    return (
        <div className={'page-center'}>
            <SideMenu
                title={'마이페이지'}
                menu={[
                    {label: '내정보', link: '/profile/my-info'},
                    {label: '상담하기', link: '/profile/my-qna', active: true},
                    {label: '나의 상담이력', link: '/profile/my-qna-history'},
                ]}
            />
            <div className={'page-content qna-edit-container'}>
                <Breadcrumb value={['홈', '마이페이지', '상담하기']}/>
                <div className={'pb-2'}>
                    <img src={require('Assets/img/icon_qna_blue.svg').default} alt={''}/><span className={'fs-21 fw-bold text-desc ms-2'}>무료 상담하기</span>
                </div>
                <div className={'qna-private fs-16'}>※ 직장정보나 연락처는 관리자 외에는 절대 공개되지 않습니다.</div>
                <div>
                    <div className={'qna-item-title'}>공개여부</div>
                    <RadioGroup row value={qnaPublic ? 'PUBLIC' : 'PRIVATE'} onChange={(e) => setQnaPublic(e.target.value === 'PUBLIC')}>
                        <FormControlLabel value="PUBLIC" control={<Radio/>} label="공개"/>
                        <FormControlLabel value="PRIVATE" control={<Radio/>} label="비공개"/>
                    </RadioGroup>
                </div>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>제목</div>
                    <TextField
                        variant="outlined"
                        placeholder={'ex) 부당해고 문의드립니다.'}
                        className={''}
                        value={qnaTitle}
                        onChange={(e) => setQnaTitle(e.target.value)}
                    />
                </div>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>거주지역<span className={'qna-file-desc ml-1'}>*비공개</span></div>
                    <TextField
                        variant="outlined"
                        placeholder={'ex) 인천시 부평구, 인천시 서구, 인천외지역'}
                        className={''}
                        value={qnaResidentialArea}
                        onChange={(e) => setQnaResidentialArea(e.target.value)}
                    />
                </div>
                <div className={'qna-item-title'}>성별<span className={'qna-file-desc ml-1'}>*비공개</span></div>
                <RadioGroup row value={qnaSex} onChange={(e) => setQnaSex(e.target.value)}>
                    <FormControlLabel value={'MAN'} control={<Radio/>} label={'남성'}/>
                    <FormControlLabel value={'WOMAN'} control={<Radio/>} label={'여성'}/>
                </RadioGroup>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>연령<span className={'qna-file-desc ml-1'}>*비공개</span></div>
                    <div>
                    <Select
                            value={qnaAge}
                            onChange={(e) => setQnaAge(e.target.value)}
                            displayEmpty
                        >
                            {
                                [
                                    {label: '10대', value: '10'},
                                    {label: '20대', value: '20'},
                                    {label: '30대', value: '30'},
                                    {label: '40대', value: '40'},
                                    {label: '50대', value: '50'},
                                    {label: '60대 이상', value: '60'},
                                ].map((v) => <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>)
                            }
                        </Select>
                    </div>
                </div>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>직장 정보 선택</div>
                    <div>
                        <Select
                            value={selectCompanyIndex}
                            onChange={(e) => onChangeCompany(e.target.value)}
                            displayEmpty
                        >
                            <MenuItem value={'new'}>신규등록</MenuItem>
                            {
                                companyList.map((v, i) => <MenuItem value={i}>{v.companyTitle}</MenuItem>)
                            }
                        </Select>
                    </div>
                </div>
                <div>
                    <div className={'qna-item-title'}>근무형태</div>
                    <RadioGroup row value={qnaWorkType} onChange={(e) => setQnaWorkType(e.target.value)}>
                        {
                            Object.keys(Apis.values.qnaWorkType).map((v) => <FormControlLabel key={v} value={v} control={<Radio/>} label={Apis.values.qnaWorkType[v]}/>)
                        }
                    </RadioGroup>
                    {
                        qnaWorkType === "WORKER" &&
                        <div className={'d-flex flex-row'}>
                            <div className={'me-2'}>
                                <Select
                                    value={qnaContractTerm}
                                    onChange={(e) => setQnaContractTerm(e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="REGULAR">정규직</MenuItem>
                                    <MenuItem value="IRREGULAR">비정규직</MenuItem>
                                </Select>
                            </div>
                            {
                                qnaContractTerm === "IRREGULAR" &&
                                <TextField
                                    variant="outlined"
                                    placeholder={'ex) 계약기간 6개월'}
                                    className={'flex-fill'}
                                    value={qnaContractPeriod}
                                    onChange={(e) => setQnaContractPeriod(e.target.value)}
                                />
                            }
                        </div>
                    }
                    {
                        qnaWorkType !== "FREELANCER" &&
                        <div className={'d-flex flex-row mt-2'}>
                            <div className={'me-2'}>
                                <Select
                                    value={qnaWorkerNum}
                                    onChange={(e) => setQnaWorkerNum(e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="OVER_5">5인 이상</MenuItem>
                                    <MenuItem value="UNDER_5">5인 미만</MenuItem>
                                </Select>
                            </div>
                        </div>
                    }
                    {
                        qnaWorkType !== "MASTER" &&
                        <div>
                            <div className={'d-flex flex-column mb-1'}>
                                <div className={'qna-item-title'}>재직기간</div>
                                <TextField
                                    variant="outlined"
                                    placeholder={'ex) 2년 3개월'}
                                    className={''}
                                    value={qnaWorkRange}
                                    onChange={(e) => setQnaWorkRange(e.target.value)}
                                />
                            </div>
                            <div className={'d-flex flex-column mb-1'}>
                                <div className={'qna-item-title'}>근무시간</div>
                                <TextField
                                    variant="outlined"
                                    placeholder={'ex) 09시 ~ 18시'}
                                    className={''}
                                    value={qnaWorkTime}
                                    onChange={(e) => setQnaWorkTime(e.target.value)}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>사업장 소재지<span className={'qna-file-desc ml-1'}>*비공개</span></div>
                    <TextField
                        variant="outlined"
                        placeholder={'ex) 인천시 부평구, 인천시 서구, 인천외지역'}
                        className={''}
                        value={qnaBusinessPlace}
                        onChange={(e) => setQnaBusinessPlace(e.target.value)}
                    />
                </div>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>업종<span className={'qna-file-desc ml-1'}>*비공개</span></div>
                    <TextField
                        variant="outlined"
                        placeholder={'ex)제조업, 건설업, 음식점업, 보건업 등'}
                        className={''}
                        value={qnaBusinessType}
                        onChange={(e) => setQnaBusinessType(e.target.value)}
                    />
                </div>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>연락처(선택)</div>
                    <TextField
                        variant="outlined"
                        placeholder={'ex) 010-1234-1234'}
                        className={''}
                        value={qnaPhone}
                        onChange={(e) => setQnaPhone(e.target.value)}
                    />
                </div>
                <span className={'qna-file-desc'}>*통화를 원하시는경우 연락처를 남겨주세요</span>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>상담분야</div>
                    <div>
                        <Select
                            value={qnaConsulting}
                            onChange={(e) => setQnaConsulting(e.target.value)}
                            displayEmpty
                        >
                            {
                                Object.keys(Apis.values.qnaConsultingType).map((v) => <MenuItem key={v} value={v}>{Apis.values.qnaConsultingType[v]}</MenuItem>)
                            }
                        </Select>
                    </div>
                </div>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>문의 내용</div>
                    <textarea
                        placeholder={'궁금한 사항을 적어주세요.'}
                        className={'qna-item-textarea'}
                        value={qnaContent}
                        onChange={(e) => setQnaContent(e.target.value)}
                    />
                </div>
                <div className={'d-flex flex-column mb-1'}>
                    <div className={'qna-item-title'}>파일첨부하기</div>
                    <div className={'qna-files'}>
                        {
                            qnaFiles.map((v, i) => (
                                <div className={'me-4'} key={i}>
                                    <span>{v.name}</span>
                                    <IconButton size={'small'} onClick={() => onRemoveFile(i)}><Close fontSize={'small'}/></IconButton>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className={'d-flex flex-row align-items-center mt-2'}>
                    <input type='file' id='file' ref={inputFile} accept=".jpg,.png,.hwp,.pdf" style={{display: 'none'}} onChange={onSelectFile}/>
                    <Button variant={'outlined'} style={{minWidth: 75}} size={'small'} className={'py-0'} onClick={() => qnaFiles.length < 2 && inputFile.current.click()}>파일첨부</Button>
                    <span className={'qna-file-desc'}>*10MB이하 jpg, png, hwp, pdf 파일만 업로드 가능합니다. (최대 2개)</span>
                </div>
                <div className={'d-flex mt-5 pt-5'}>
                    <Button variant={'contained'} size={'large'} className={'flex-fill'} onClick={onSave}>{param.id ? '상담수정' : '상담등록'}</Button>
                </div>
            </div>
        </div>
    )
}

export default MyQnaEdit;