import React, {useState, useEffect} from 'react';
import {FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField} from "@mui/material";
import Dialog from "Components/Dialog";
import {useDispatch, useSelector} from "react-redux";
import {updateCompanyList} from "Store/Actions";
import * as Apis from "../../Api";

function ModalCompanyEdit({open, onClose, editIndex}) {
    const dispatch = useDispatch();
    const companyList = useSelector(state => state.authUser.companyList);
    const [companyTitle, setCompanyTitle] = useState("");
    const [qnaWorkType, setQnaWorkType] = useState("WORKER");
    const [qnaContractTerm, setQnaContractTerm] = useState("REGULAR");
    const [qnaContractPeriod, setQnaContractPeriod] = useState("");
    const [qnaWorkerNum, setQnaWorkerNum] = useState("OVER_5");
    const [qnaWorkRange, setQnaWorkRange] = useState("");
    const [qnaWorkTime, setQnaWorkTime] = useState("");

    useEffect(() => {
        if (open) {
            if (editIndex === -1) {
                setCompanyTitle("");
                setQnaWorkType("WORKER");
                setQnaContractTerm("REGULAR");
                setQnaContractPeriod("");
                setQnaWorkerNum("OVER_5");
                setQnaWorkRange("");
                setQnaWorkTime("");
            } else {
                setCompanyTitle(companyList[editIndex].companyTitle);
                setQnaWorkType(companyList[editIndex].qnaWorkType);
                setQnaContractTerm(companyList[editIndex].qnaContractTerm);
                setQnaContractPeriod(companyList[editIndex].qnaContractPeriod);
                setQnaWorkerNum(companyList[editIndex].qnaWorkerNum);
                setQnaWorkRange(companyList[editIndex].qnaWorkRange);
                setQnaWorkTime(companyList[editIndex].qnaWorkTime);
            }
        }
    }, [open]);

    const onSave = () => {
        const companyInfo = {
            isDefault: companyList ? companyList.length === 0 : true,
            companyTitle,
            qnaWorkType,
            qnaContractTerm,
            qnaContractPeriod,
            qnaWorkerNum,
            qnaWorkRange,
            qnaWorkTime,
        };
        if (editIndex === -1) {
            companyList.push(companyInfo);
        } else {
            companyInfo.isDefault = companyList[editIndex].isDefault;
            companyList[editIndex] = companyInfo;
        }
        console.log(editIndex, companyList)
        dispatch(updateCompanyList([...companyList]));
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={'직장정보'}
            confirmLabel={'저장'}
            onConfirm={onSave}
        >
            <div className={'d-flex flex-column mb-2'}>
                <span className={'fs-14 mb-1'}>제목</span>
                <TextField
                    variant="outlined"
                    className={'fs-14'}
                    placeholder={'ex) 직장1'}
                    value={companyTitle}
                    onChange={(e) => setCompanyTitle(e.target.value)}
                />
            </div>
            <div>
                <div className={'fs-14 mt-4'}>근무형태</div>
                <RadioGroup row value={qnaWorkType} onChange={(e) => setQnaWorkType(e.target.value)}>
                    {
                        Object.keys(Apis.values.qnaWorkType).map((v) => <FormControlLabel key={v} value={v} control={<Radio size={'small'}/>} label={<span className={'fs-14'}>{Apis.values.qnaWorkType[v]}</span>}/>)
                    }
                </RadioGroup>
                {
                    qnaWorkType === "WORKER" &&
                    <div className={'d-flex flex-row'}>
                        <div className={'me-2'}>
                            <Select
                                value={qnaContractTerm}
                                onChange={(e) => setQnaContractTerm(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="REGULAR">정규직</MenuItem>
                                <MenuItem value="IRREGULAR">비정규직</MenuItem>
                            </Select>
                        </div>
                        {
                            qnaContractTerm === "IRREGULAR" &&
                            <TextField
                                variant="outlined"
                                placeholder={'ex) 계약기간 6개월'}
                                className={'flex-fill'}
                                value={qnaContractPeriod}
                                onChange={(e) => setQnaContractPeriod(e.target.value)}
                            />
                        }
                    </div>
                }
                {
                    qnaWorkType !== "FREELANCER" &&
                    <div className={'d-flex flex-row mt-2'}>
                        <div className={'me-2'}>
                            <Select
                                value={qnaWorkerNum}
                                onChange={(e) => setQnaWorkerNum(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="OVER_5">5인 이상</MenuItem>
                                <MenuItem value="UNDER_5">5인 미만</MenuItem>
                            </Select>
                        </div>
                    </div>
                }
                {
                    qnaWorkType !== "MASTER" &&
                    <div>
                        <div className={'d-flex flex-column mb-1'}>
                            <div className={'fs-14 mt-4'}>재직기간</div>
                            <TextField
                                variant="outlined"
                                placeholder={'ex) 2년 3개월'}
                                className={''}
                                value={qnaWorkRange}
                                onChange={(e) => setQnaWorkRange(e.target.value)}
                            />
                        </div>
                        <div className={'d-flex flex-column mb-1'}>
                            <div className={'fs-14 mt-4'}>근무시간</div>
                            <TextField
                                variant="outlined"
                                placeholder={'ex) 09시 ~ 18시'}
                                className={''}
                                value={qnaWorkTime}
                                onChange={(e) => setQnaWorkTime(e.target.value)}
                            />
                        </div>
                    </div>
                }
            </div>
        </Dialog>
    )
}

export default ModalCompanyEdit;

