import React, {useState, useEffect} from 'react';
import SideMenu from "Components/SideMenu";
import Breadcrumb from "Components/Breadcrumb";
import * as Apis from "Api";

function Usage() {
    const [text, setText] = useState('');

    useEffect(() => {
        Apis.getTerms().then((resp) => {
            setText(resp.value);
        });
    }, [])

    return (
        <div className={'page-center'}>
            <SideMenu
                title={'약관 정보'}
                menu={[
                    {label: '개인정보처리방침', link: '/privacy'},
                    {label: '이용약관', link: '/usage', active: true},
                ]}
            />
            <div className={'page-content info-container'}>
                <Breadcrumb value={['홈', '약관정보', '이용약관']}/>
                <div dangerouslySetInnerHTML={{__html: text}} />
            </div>
        </div>
    )
}

export default Usage;