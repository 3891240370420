import React from "react";
import DefaultAppLayout from './DefaultAppLayout';
import {Outlet} from "react-router-dom";

const AppLayout = () => {
    return (
        <DefaultAppLayout>
            <Outlet />
        </DefaultAppLayout>
    )
}

export default AppLayout;