import React, {useState, useEffect, useRef} from 'react';
import {TextField, InputAdornment, Button} from '@mui/material';
import {Person, LockOpen, EmailOutlined} from '@mui/icons-material';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {signinUser} from 'Store/Actions';
import Dialog from 'Components/Dialog';
import {NotificationManager} from "react-notifications";
import * as Apis from 'Api';
import {isMobile} from "react-device-detect";

function LoginPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const naverButtonRef = useRef();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showResetModal, setShowResetModal] = useState(false);
    const [findUsername, setFindUsername] = useState('');
    const [findEmail, setFindEmail] = useState('');
    const [showResetEmailModal, setShowResetEmailModal] = useState(false);

    useEffect(() => {
        initializeNaverLogin();
    }, []);

    useEffect(() => {
        setFindUsername('');
        setFindEmail('');
    }, [showResetModal]);

    const initializeNaverLogin = () => {
        const {naver} = window;
        const naverLogin = new naver.LoginWithNaverId({
            clientId: '8gaqKGIssOSb5Jr69rWz',
            callbackUrl: window.location.origin + '/third-login/naver',
            isPopup: false, // popup 형식으로 띄울것인지 설정
            loginButton: { color: 'green', type: 1, height: '47' }, //버튼의 스타일, 타입, 크기를 지정
        });
        naverLogin.init();
    };

    const onKeyDown = (e) => {
        if(e.key === 'Enter') {
            onLogin();
        }
    }

    const onLogin = () => {
        Apis.login(email, password).then((result) => {
            dispatch(signinUser(result.info, result.token, navigate));
        }).catch((e) => {
            setErrorMsg('아이디나 비밀번호가 정확하지 않습니다.');
        }).finally(() => {
        });
    }

    const onResetPassword = () => {
        Apis.resetPassword(findUsername, findEmail).then((resp) => {
            if(resp.result === 'success') {
                setShowResetModal(false);
                setShowResetEmailModal(true);
            } else {
                NotificationManager.error(resp.msg);
            }
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        })
    }

    return (
        <div className={'login-container d-flex justify-content-center'}>
            <div className={'login-content'}>
                <div className={'d-flex justify-content-center mb-5'}>
                    <img className={'login-logo'} src={require('Assets/img/logo2.png')} alt={''}/>
                </div>
                <TextField
                    variant="outlined"
                    placeholder={'아이디(이메일)'}
                    className={'mt-2 mb-2'}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Person/></InputAdornment>,
                    }}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setErrorMsg('');
                    }}
                />
                <TextField
                    variant="outlined"
                    placeholder={'비밀번호'}
                    className={'mb-3'}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><LockOpen/></InputAdornment>,
                    }}
                    type="password"
                    onKeyDown={onKeyDown}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setErrorMsg('')
                    }}
                />
                {errorMsg !== '' && <span className={'fs-12 text-red'}>{errorMsg}</span>}
                <Button variant={'contained'} className={'mb-2'} onClick={onLogin}>로그인</Button>
                <div className={'d-flex flex-row justify-content-between'}>
                    <NavLink
                        className={'btn-underline fs-13'}
                        to={'/signup'}
                    >
                        회원가입
                    </NavLink>
                    <span
                        className={'btn-underline fs-13'}
                        onClick={() => setShowResetModal(true)}
                    >
                        비밀번호찾기
                    </span>
                </div>
                <Button variant={'contained'} className={'naver-sso-btn'} onClick={() => naverButtonRef.current.firstChild.click()}>
                    <img src={require('Assets/img/icon_naver.svg').default} alt={''}/>
                    <span className={'ms-2'}>네이버 로그인</span>
                </Button>
                <div id='naverIdLogin' ref={naverButtonRef} style={{display: 'none'}}/>
            </div>
            <Dialog open={showResetModal} onClose={() => setShowResetModal(false)} title={'비밀번호 찾기'} confirmLabel={'확인'} onConfirm={onResetPassword}>
                <div className={'page-title'} style={{width: isMobile ? 'auto' : 535}}>비밀번호 찾기</div>
                <div className={'d-flex flex-column mb-4 mt-4'}>
                    <span>이름</span>
                    <TextField
                        variant="outlined"
                        placeholder={'ex) 홍길동'}
                        value={findUsername}
                        onChange={(e) => setFindUsername(e.target.value)}
                    />
                </div>
                <div className={'d-flex flex-column'}>
                    <span>아이디(이메일)</span>
                    <TextField
                        variant="outlined"
                        placeholder={'ex) email@naver.com'}
                        onKeyDown={(e) => (e.key === 'Enter' && onResetPassword())}
                        value={findEmail}
                        onChange={(e) => setFindEmail(e.target.value)}
                    />
                </div>
                <span className={'mb-4 text-teal fs-14'}>*회원 가입 시 입력한 이메일을 입력해주세요.</span>
            </Dialog>
            <Dialog
                open={showResetEmailModal} onClose={() => setShowResetEmailModal(false)}
                title={'비밀번호 찾기'} confirmLabel={'확인'} showCancel={false} onConfirm={() => setShowResetEmailModal(false)}
            >
                <div className={'reset-email-modal'}>
                    <div className={'email-icon'}>
                        <EmailOutlined />
                    </div>
                    <span className={'text-center mt-4'}>입력하신 이메일로<br/> 임시비밀번호가 발송되었습니다.</span>
                    <span className={'text-center mt-3'}>임시번호로 로그인 후 반드시<br/> 비밀번호를 변경해 주세요.</span>
                </div>
            </Dialog>
        </div>
    )
}

export default LoginPage;