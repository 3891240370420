import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Route, Routes, useLocation, Outlet} from 'react-router-dom';
import AppLayout from 'Components/AppLayout';
import AppLogin from 'Routes/nologin/login';
import ThirdLogin from 'Routes/nologin/ThirdLogin';
import AppSignup from 'Routes/nologin/signup';
import HomePage from 'Routes/home';
import MyInfo from 'Routes/profile/MyInfo';
import MyQnaEdit from "Routes/profile/MyQnaEdit";
import MyQnaHistory from "Routes/profile/MyQnaHistory";
import MyQnaView from "Routes/profile/MyQnaView";
import Intro from 'Routes/intro';
import Step from 'Routes/step';
import Knowledge from 'Routes/knowledge';
import Issue from 'Routes/issue';
import Board from 'Routes/board';
import LawInfo from 'Routes/law-info';
import Privacy from 'Routes/nologin/privacy';
import Usage from 'Routes/nologin/usage';
import IssueView from 'Routes/issue/IssueView';
import QnaView from 'Routes/board/QnaView';
import Search from 'Routes/home/Search';


function PrivateOutlet() {
    const authUser = useSelector(state => state.authUser);
    const {isLogin} = authUser;
    return isLogin ? <Outlet /> : <Navigate to="/login" />;
}

function App(props) {
    const location = useLocation();
    const authUser = useSelector(state => state.authUser);
    const {user} = authUser;
    if (location.pathname === '/') {
        // if (user === null) {
        //     return (<Navigate  to={'/signin'} />);
        // } else {
        //     return (<Navigate  to={'/buy-mars'} />);
        // }
    }
    return (
        <Routes>
            <Route path="/" element={<AppLayout/>}>
                <Route index element={<Navigate to={'home'}/>}/>
                <Route path="/login" element={<AppLogin/>}/>
                <Route path="/third-login/:type" element={<ThirdLogin/>}/>
                <Route path="/signup" element={<AppSignup/>}/>
                <Route path="/home" element={<HomePage/>}/>
                <Route path="/search" element={<Search/>}/>
                <Route path="/profile" element={<PrivateOutlet />}>
                    <Route path={'my-info'} element={<MyInfo/>}/>
                    <Route path={'my-qna'} element={<MyQnaEdit/>}/>
                    <Route path={'my-qna/:id'} element={<MyQnaEdit/>}/>
                    <Route path={'my-qna-history'} element={<MyQnaHistory/>}/>
                    <Route path={'my-qna-history/:id'} element={<MyQnaView/>}/>
                    <Route index element={<Navigate to={'my-info'}/>}/>
                </Route>
                <Route path="/intro" element={<Intro/>}/>
                <Route path="/step" element={<Step/>}/>
                <Route path="/knowledge" element={<Knowledge/>}/>
                <Route path="/knowledge/view" element={<IssueView/>}/>
                <Route path="/issue" element={<Issue/>}/>
                <Route path="/issue/view" element={<IssueView/>}/>
                <Route path="/board" element={<Board/>}/>
                <Route path="/board/view" element={<QnaView/>}/>
                <Route path="/law-info" element={<LawInfo/>}/>
                <Route path="/law-info/view" element={<IssueView/>}/>
                <Route path="/privacy" element={<Privacy/>}/>
                <Route path="/usage" element={<Usage/>}/>
            </Route>
        </Routes>
    );
}

export default App;
