import React, {useState, useEffect} from 'react';
import {Button} from '@mui/material';
import * as Apis from 'Api';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Dialog from "./Dialog";
import {NotificationManager} from "react-notifications";
import JSONParseDefault from "json-parse-default";

function QnaViewComponent({id}) {
    const navigate = useNavigate();
    const authUser = useSelector(state => state.authUser);
    const [itemInfo, setItemInfo] = useState({});
    const [qnaFiles, setQnaFiles] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        Apis.qnaInfo(id).then((resp) => {
            setItemInfo(resp);
            setQnaFiles(JSONParseDefault(resp.qnaFiles, null, []));
        });
    }, [id]);

    const onDeleteItem = () => {
        Apis.qnaDelete(id).then((resp) => {
            navigate(-1);
            setShowDeleteModal(false);
        }).catch((e) => {
            NotificationManager.error('오류가 발생했습니다.');
        });
    }

    return (
        <div className={'qna-view-component'}>
            <div className={'d-flex flex-row justify-content-between border-bottom pb-2'}>
                <span className={'qna-title'}>{itemInfo.qnaTitle}</span>
                {
                    (authUser.userInfo?.id === itemInfo.qnaUserId && itemInfo.qnaStatus === "PENDING") &&
                    <div>
                        <Button variant={'contained'} size={'small'} color={'warning'} className={'me-2'} onClick={() => navigate('/profile/my-qna/' + id)}>수정</Button>
                        <Button variant={'outlined'} size={'small'} color={'info'} onClick={() => setShowDeleteModal(true)}>삭제</Button>
                    </div>
                }
            </div>
            <div className={'profile-line'}>
                <span>공개여부</span>
                <span>{itemInfo.qnaPublic ? '공개' : '비공개'}</span>
            </div>
            {
                authUser.userInfo?.id === itemInfo.qnaUserId &&
                <div className={'profile-line'}>
                    <span>거주지역</span>
                    <span>{itemInfo.qnaResidentialArea}</span>
                </div>
            }
            {
                authUser.userInfo?.id === itemInfo.qnaUserId &&
                <div className={'profile-line'}>
                    <span>성별</span>
                    <span>{{MAN: '남성', WOMAN: '여성'}[itemInfo.qnaSex]}</span>
                </div>
            }
            {
                authUser.userInfo?.id === itemInfo.qnaUserId &&
                <div className={'profile-line'}>
                    <span>연령</span>
                    <span>{itemInfo.qnaAge}{itemInfo.qnaAge !== '60' ? '대' : '대 이상'}</span>
                </div>
            }
            <div className={'profile-line'}>
                <span>근무형태</span>
                <span>{Apis.values.qnaConsultingType[itemInfo.qnaConsulting]}</span>
            </div>
            <div className={'profile-line'}>
                <span>근로자수</span>
                <span>{Apis.values.qnaWorkerNum[itemInfo.qnaWorkerNum]}</span>
            </div>
            <div className={'profile-line'}>
                <span>재직기간</span>
                <span>{itemInfo.qnaWorkRange}</span>
            </div>
            <div className={'profile-line'}>
                <span>근무시간</span>
                <span>{itemInfo.qnaWorkTime}</span>
            </div>
            {
                authUser.userInfo?.id === itemInfo.qnaUserId &&
                <div className={'profile-line'}>
                    <span>사업장 소재지</span>
                    <span>{itemInfo.qnaBusinessPlace}</span>
                </div>
            }
            {
                authUser.userInfo?.id === itemInfo.qnaUserId &&
                <div className={'profile-line'}>
                    <span>업종</span>
                    <span>{itemInfo.qnaBusinessType}</span>
                </div>
            }
            <div className={'profile-line'}>
                <span>상담분야</span>
                <span>{Apis.values.qnaConsultingType[itemInfo.qnaConsulting]}</span>
            </div>
            <div className={'profile-line d-flex flex-column flex-md-row'}>
                <span>내용</span>
                <div className={'qna-content'} dangerouslySetInnerHTML={{__html: itemInfo.qnaContent?.replace(/\n/g, '<br />')}}></div>
            </div>
            {
                qnaFiles.length > 0 &&
                <div className={'profile-line d-flex'}>
                    <span>첨부파일</span>
                    <div>
                        {
                            qnaFiles.map((v, i) => <a key={i} className={'me-2'} href={Apis.imageBasePath + v.path} target='_blank'>{v.name}</a>)
                        }
                    </div>
                </div>
            }
            <div className={'qna-answer'}>
                <img src={require('Assets/img/logo3.png')} alt={''}/>
                <div dangerouslySetInnerHTML={{__html: itemInfo.qnaStatus === "PENDING" ? "답변대기중입니다." : itemInfo.qnaAnswer?.replace(/\n/g, '<br />')}}/>
            </div>
            <Dialog
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                title={'삭제'}
                confirmLabel={'삭제'}
                onConfirm={onDeleteItem}
            >
                <div className={'d-flex flex-column mb-2'}>
                    상담내역을 삭제할까요?
                </div>
            </Dialog>
        </div>
    )
}

export default QnaViewComponent;