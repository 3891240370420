import React from 'react';
import classNames from "classnames";
import {useLocation, useNavigate} from "react-router-dom";

function SideMenu({title, menu}) {
    const navigate = useNavigate();
    const location = useLocation();
    const renderMenuItem = (item, index) => {
        return (
            <div
                key={index}
                className={classNames({'active': item.active})}
                onClick={() => (location.pathname !== item.link) && navigate(item.link)}
            >
                {item.label}
            </div>
        )
    }
    return (
        <div className={'side-menu'}>
            <div className={'page-title side-menu-title'}>{title}</div>
            <div className={'side-menu-child'}>
                {
                    menu.map((v, i) => renderMenuItem(v, i))
                }
            </div>
        </div>
    )
}

export default SideMenu;